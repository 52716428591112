import { ThreadModel, ThreadModelHash, IThreadModel, IThreadModelHash } from './model';
import { ThreadService } from './service';

export const threadModelSelector = (input: IThreadModel[]): ThreadModel[] => {
  if (!input) return [];
  const threadService = new ThreadService();
  return input.map(w => threadService.trans2Model(w.id, w));
};

export const threadModelHashSelector = (input: IThreadModelHash): ThreadModelHash => {
  if (!input) return {};
  const result = {};
  const threadService = new ThreadService();
  Object.entries(input).forEach(([key, value]: any) => {
    if (value) result[key] = threadService.trans2Model(key, value);
  });
  return result;
};

export const threadModelIdSelector = (input: IThreadModelHash, id: string): ThreadModel => {
  if (!input || !id) return null;
  return new ThreadService().trans2Model(id, input[id]);
};

export const allOtherWorkerIdThreadHashSelector = (
  input: IThreadModelHash,
  workerIds: string
): ThreadModelHash => {
  if (!input) return {};
  const result = {};
  const service = new ThreadService();
  Object.entries(input).forEach(([key, value]: any) => {
    const otherWorkerId = value.workerIds.filter(v => v !== workerIds)[0];
    result[otherWorkerId] = service.trans2Model(key, value);
  });
  return result;
};

export const allNotReadCountSelector = (input: IThreadModel[], workerId: string): number => {
  if (!input) return 0;
  const service = new ThreadService();
  const result = input.reduce((sum, value) => {
    return sum + service.trans2Model(value.id, value).getUnreadCount(workerId);
  }, 0);
  return result;
};

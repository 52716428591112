import MomentUtils from '@date-io/moment';
import { Moment } from 'moment';
import 'moment/locale/ja';

export class ExtendedUtils extends MomentUtils {
  yearMonthFormat: 'YYYY/MM';
  yearFormat: 'YYYY';

  //@ts-ignore
  format(date: any, string) {
    return date.format(string);
  }
  getCalendarHeaderText(date: Moment) {
    return date.format('YYYY年M月');
  }
  getDatePickerHeaderText(date: Moment) {
    return date.format('M月D日');
  }
  getDateTimePickerHeaderText(date: Moment) {
    return date.format('MM/DD');
  }
  getYearText(date: Moment) {
    return date.format('YYYY年');
  }
  getMonthText(date: Moment) {
    return date.format('MM月');
  }
  getDayText(date: Moment) {
    return date.format('DD');
  }
  getHourText(date: Moment) {
    return date.format('HH');
  }
  getMinuteText(date: Moment) {
    return date.format('mm');
  }
}

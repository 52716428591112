import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector } from '../../__share__/modules/reducer';
import AppDataHolder from '../../__share__/modules/common/appDataHolder';
import SlashScreen from '../components/site/SplashScreen';

type Props = {
  children: any;
};

function AuthRoute(props: Props) {
  const { children } = props;
  const auth = useSelector(state => state.firebase.auth);
  const profile = useSelector(state => state.firebase.profile);
  const [isAppDataLoaded, setIsAppDataLoaded] = useState(false);

  useEffect(() => {
    const app = AppDataHolder.getInstance();
    if (!app.getOrganizationId()) {
      app.setOrganizationId(profile.organizationId);
    }
    if (!app.getAccountId()) {
      app.setAccountId(auth.uid);
    }
    if (!app.getWorkerId()) {
      app.setWorkerId(profile.workerId);
    }
    if (app.getOrganizationId() && app.getAccountId() && app.getWorkerId()) {
      setIsAppDataLoaded(true);
    }
  }, [auth.uid, profile.organizationId, profile.workerId]);

  if (auth.isLoaded && auth.isEmpty) {
    return <Redirect to="/login" />;
  }
  if (!profile.organizationId || !isAppDataLoaded) return <SlashScreen />;
  return children;
}

export default React.memo(AuthRoute);

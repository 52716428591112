import { BasicOption } from '../../types/options';
import { BaseConstants } from './_BaseConstants';

export class WeekDay extends BaseConstants {
  static readonly SUNDAY = new WeekDay('SUNDAY', '日', 6, 6, 0, 7);
  static readonly MONDAY = new WeekDay('MONDAY', '月', 0, 0, 1, 1);
  static readonly TUESDAY = new WeekDay('TUESDAY', '火', 1, 1, 2, 2);
  static readonly WEDNESDAY = new WeekDay('WEDNESDAY', '水', 2, 2, 3, 3);
  static readonly THURSDAY = new WeekDay('THURSDAY', '木', 3, 3, 4, 4);
  static readonly FRIDAY = new WeekDay('FRIDAY', '金', 4, 4, 5, 5);
  static readonly SATURDAY = new WeekDay('SATURDAY', '土', 5, 5, 6, 6);

  private constructor(
    public readonly key: string,
    public readonly label: string,
    public readonly rrule: any,
    public readonly code: number,
    public readonly fullCalenderCode: number,
    public readonly javaCode: number
  ) {
    super();
  }
  static getByCode(code: number | string): WeekDay {
    return this.getAll().find(v => v.code === Number(code));
  }
  static getByLabel(val: string): WeekDay {
    return this.getAll().find(v => v.label === val);
  }
  static getByCalenderCode(val: number): WeekDay {
    return this.getAll().find(v => v.fullCalenderCode === val);
  }
  static getWeekDayFromSunday(): WeekDay[] {
    return [
      WeekDay.SUNDAY,
      WeekDay.MONDAY,
      WeekDay.TUESDAY,
      WeekDay.WEDNESDAY,
      WeekDay.THURSDAY,
      WeekDay.FRIDAY,
      WeekDay.SATURDAY,
    ];
  }
  static getWeekDayFromMonday(): WeekDay[] {
    return [
      WeekDay.MONDAY,
      WeekDay.TUESDAY,
      WeekDay.WEDNESDAY,
      WeekDay.THURSDAY,
      WeekDay.FRIDAY,
      WeekDay.SATURDAY,
      WeekDay.SUNDAY,
    ];
  }
  static getOptions(): BasicOption[] {
    return WeekDay.getWeekDayFromMonday().map(v => {
      return { key: String(v.code), value: String(v.code), label: v.label };
    });
  }
}

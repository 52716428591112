export function isEmpty(target) {
  if (!target) {
    return true;
  }
  if (Array.isArray(target)) {
    return target.length === 0;
  }
  return false;
}

export function isBothEmpty(target1, target2) {
  return isEmpty(target1) && isEmpty(target2);
}

export function existsEmpty(target1, target2) {
  return isEmpty(target1) || isEmpty(target2);
}
export function removeItem<T>(arr: Array<T>, value: T): Array<T> {
  const index = arr.indexOf(value);
  if (index > -1) {
    arr.splice(index, 1);
  }
  return arr;
}
export function extractOverlap(target1, target2) {
  const arr1arr2 = [...target1, ...target2];
  const duplicatedArr = arr1arr2.filter(item => target1.includes(item) && target2.includes(item));
  //@ts-ignore
  return [...new Set(duplicatedArr)];
}

export function isEmptyObject(obj) {
  return Object.keys(obj).length === 0;
}
export function isEqualSet(as, bs): boolean {
  if (as.size !== bs.size) return false;
  for (var a of as) if (!bs.has(a)) return false;
  return true;
}
export function isEqualArray(as: Array<any>, bs: Array<any>): boolean {
  return isEqualSet(new Set(as), new Set(bs));
}
export const debounce = <T extends (...args: any[]) => unknown>(
  callback: T,
  delay = 250
): ((...args: Parameters<T>) => void) => {
  let timeoutId: NodeJS.Timeout; // Node.jsの場合はNodeJS.Timeout型にする
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => callback(...args), delay);
  };
};

export function findLastIndex(array, searchKey, searchValue) {
  var index = array
    .slice()
    .reverse()
    .findIndex(x => x[searchKey] === searchValue);
  var count = array.length - 1;
  var finalIndex = index >= 0 ? count - index : index;
  return finalIndex;
}

// export enum Role {
//   MANAGER = '社員（サービス提供責任者）',
//   HELPER = '社員（ヘルパー）',
//   PART_HELPER = 'パートヘルパー',
//   REGISTER_HELPER = '登録ヘルパー',
// }
export const ROLE_ANYONE = 'ROLE_ANYONE';

export enum DegreeOfCareRequired {
  ONE = '1',
  TWO = '2',
  THREE = '3',
  FOUR = '4',
  FIVE = '5',
}

export enum Prefecture {
  P1 = '北海道',
  P2 = '青森県',
  P3 = '岩手県',
  P4 = '宮城県',
  P5 = '秋田県',
  P6 = '山形県',
  P7 = '福島県',
  P8 = '茨城県',
  P9 = '栃木県',
  P10 = '群馬県',
  P11 = '埼玉県',
  P12 = '千葉県',
  P13 = '東京都',
  P14 = '神奈川県',
  P15 = '新潟県',
  P16 = '富山県',
  P17 = '石川県',
  P18 = '福井県',
  P19 = '山梨県',
  P20 = '長野県',
  P21 = '岐阜県',
  P22 = '静岡県',
  P23 = '愛知県',
  P24 = '三重県',
  P25 = '滋賀県',
  P26 = '京都府',
  P27 = '大阪府',
  P28 = '兵庫県',
  P29 = '奈良県',
  P30 = '和歌山県',
  P31 = '鳥取県',
  P32 = '島根県',
  P33 = '岡山県',
  P34 = '広島県',
  P35 = '山口県',
  P36 = '徳島県',
  P37 = '香川県',
  P38 = '愛媛県',
  P39 = '高知県',
  P40 = '福岡県',
  P41 = '佐賀県',
  P42 = '長崎県',
  P43 = '熊本県',
  P44 = '大分県',
  P45 = '宮崎県',
  P46 = '鹿児島県',
  P47 = '沖縄県',
}

export enum Sex {
  M = '男性',
  F = '女性',
}

export enum ServiceKind {
  BODY = '身体',
  LIFE = '生活',
  OTHER = 'その他',
}

export enum EventWorkerRoleKind {
  MAIN = '担当',
  FIRST = '初回同行',
  TRAINING = '研修',
}
// export enum Skill {
//   '介護福祉士',
//   '居宅・介護員（ヘルパー１～２級）',
//   '居宅・介護員（ヘルパー３級）',
//   '知的障害者外出介護（移動支援）',
//   '全身性障害者外出介護（移動支援）',
//   '視覚障害者外出介護（移動支援）',
//   '介護職員基礎研修',
//   '行動援護従事者',
//   '重度訪問介護従事者',
//   '一般乗用旅客自動車運送事業',
//   '介護職員初任者研修',
//   '実務者研修',
// }

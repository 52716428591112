import React, { useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Theme, Avatar, Box, ButtonBase, Menu, MenuItem, makeStyles, Tooltip } from '@material-ui/core';
import { logout } from '../../../../__share__/modules/auth/actions';
import { purple } from '../../../../theme/common';
import { useProfile } from '../../../app/AppContext';
import Typo from '../../../components/site/Typo';

const useStyles = makeStyles((theme: Theme) => ({
  popover: {
    width: 200,
  },
  workerAvatar: purple(theme, true, 32),
}));

function Account() {
  const classes = useStyles();
  const history = useHistory();
  const profile = useProfile();
  const ref: any = useRef(null);
  const dispatch = useDispatch();
  // const account = useSelector((state: AccountState) => state.account);
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      handleClose();
      await dispatch(logout());
      history.push('/');
    } catch (error) {
      enqueueSnackbar('Unable to logout', {
        variant: 'error',
      });
    }
  };

  return (
    <>
      <Tooltip title={profile.email}>
        <Box
          display="flex"
          alignItems="center"
          component={ButtonBase}
          onClick={handleOpen}
          // @ts-ignore
          ref={ref}
        >
          <Avatar alt="ユーザ" className={classes.workerAvatar} />
          <Typo value={profile.worker.getFullName()} />
        </Box>
      </Tooltip>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isOpen}
      >
        <MenuItem component={RouterLink} to="/app/account/edit/login">
          アカウント情報修正
        </MenuItem>
        <MenuItem onClick={handleLogout}>ログアウト</MenuItem>
      </Menu>
    </>
  );
}
export default React.memo(Account);

import React from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { createStyles, makeStyles, ThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { SnackbarProvider } from 'notistack';
import Routes from './Routes';
import { createTheme } from './theme';
import { ExtendedUtils } from './utils/ExtenderMomentUtils';
import ErrorBoundary from './views/app/ErrorBoundary';
import ViewStateController from './views/app/ViewStateController';
import * as am4core from '@amcharts/amcharts4/core';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

// "e2e": "jest -c ./src/e2e/config.json",
// "@types/jest": "^24.9.1",
// "ts-jest": "^26.3.0",
// "@testing-library/jest-dom": "^4.2.4",
//     "@testing-library/react": "^9.3.2",
//     "@testing-library/user-event": "^7.1.2",
am4core.useTheme(am4themes_animated);
const history = createBrowserHistory();

const useStyles = makeStyles(() =>
  createStyles({
    '@global': {
      '*': {
        boxSizing: 'border-box',
        margin: 0,
        padding: 0,
      },
      html: {
        '-webkit-font-smoothing': 'antialiased',
        '-moz-osx-font-smoothing': 'grayscale',
        height: '100%',
        width: '100%',
      },
      body: {
        height: '100%',
        width: '100%',
        '@media print': {
          width: '1200px',
        },
      },
      '#root': {
        height: '100%',
        width: '100%',
      },
      '.__react_component_tooltip': {
        zIndex: '1200!important', // appBarは1000,modalは1300
      },
    },
  })
);

function App() {
  useStyles();

  return (
    <ThemeProvider theme={createTheme()}>
      <MuiPickersUtilsProvider utils={ExtendedUtils}>
        <SnackbarProvider maxSnack={1}>
          <Router history={history}>
            <ErrorBoundary>
              {/* <ScrollReset /> */}
              <Routes />
            </ErrorBoundary>
          </Router>
          <ViewStateController />
        </SnackbarProvider>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
}

export default App;

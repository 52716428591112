import { WorkerModel, WorkerModelHash, IWorkerModelHash, IWorkerModel } from './model';
import { WorkerService } from './service';

export const workerModelIdSelector = (input: IWorkerModelHash, id: string): WorkerModel => {
  if (!input || !id) return null;
  return new WorkerService().trans2Model(id, input[id]);
};

export const workerModelSelector = (input: IWorkerModel[], onlyValid = true): WorkerModel[] => {
  if (!input) return [];
  const workerService = new WorkerService();
  if (onlyValid) {
    return input.filter((v: any) => v.isValid).map(w => workerService.trans2Model(w.id, w));
  } else {
    return input.map(w => workerService.trans2Model(w.id, w));
  }
};

// 無効化ユーザのデータが残っているため基本は全て取得
export const workerModelHashSelector = (input: IWorkerModelHash, onlyValid = false): WorkerModelHash => {
  if (!input) return {};
  const result = {};
  const workerService = new WorkerService();
  if (onlyValid) {
    Object.keys(input)
      .filter((k: any) => input[k].isValid)
      .forEach(k => (result[k] = workerService.trans2Model(k, input[k])));
  } else {
    Object.keys(input).forEach(k => (result[k] = workerService.trans2Model(k, input[k])));
  }
  return result;
};

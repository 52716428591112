import firebase from 'firebase/app';
import store from '../modules/store';
import { createFirestoreInstance } from 'redux-firestore';

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const rrfProps = {
  firebase,
  config: {
    userProfile: 'accounts',
    useFirestoreForProfile: true,
    enableClaims: true,
  },
  dispatch: store.dispatch,
  createFirestoreInstance,
};

import { isEqualSet } from '../../utils/operateUtil';
import BaseModel, { IBaseModel } from '../common/_BaseModel';

export type ThreadModelHash = { [key: string]: ThreadModel };
export type IThreadModelHash = { [key: string]: IThreadModel };
export type IThreadModel = IBaseModel & {
  workerIds: string[];
  unreadCount: number[];
  latestMessage: string;
  latestMessageDate: Date;
};
export class ThreadModel extends BaseModel implements IThreadModel {
  unreadCount: number[] = [0, 0];
  workerIds: string[] = ['', ''];
  latestMessage: string = '';
  latestMessageDate: Date = null;

  constructor(param?: Partial<IThreadModel>) {
    super();
    Object.assign(this, param);
  }

  public isSame(workerIds: string[]): boolean {
    return isEqualSet(new Set(this.workerIds), new Set(workerIds));
  }

  public getOtherId(workerId: string): string {
    return this.workerIds.find(v => v !== workerId);
  }

  public getUnreadCount(workerId: string): number {
    return this.unreadCount[this.workerIds.indexOf(workerId)];
  }

  public clearUnreadCount(workerId: string): void {
    const newCount = [...this.unreadCount];
    newCount[this.workerIds.indexOf(workerId)] = 0;
    this.unreadCount = newCount;
  }
}

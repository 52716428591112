import { combineReducers } from '@reduxjs/toolkit';
import { useSelector as rawUseSelector, shallowEqual } from 'react-redux';
import { firestoreReducer } from 'redux-firestore';
import { firebaseReducer } from 'react-redux-firebase';
import { errorModule, ErrorState } from './error';
import { viewModule, ViewState } from './view';
import { authModule, AuthState } from './auth';
import { EventState, eventModule } from './events';
import { CareplanState, careplanModule } from './careplans';

type ReducerType = {
  error: ErrorState;
  auth: AuthState;
  event: EventState;
  careplan: CareplanState;
  view: ViewState;
  firestore: any;
  firebase: any;
};
const rootReducer = combineReducers<ReducerType>({
  error: errorModule.reducer,
  view: viewModule.reducer,
  auth: authModule.reducer,
  event: eventModule.reducer,
  careplan: careplanModule.reducer,
  firestore: firestoreReducer,
  firebase: firebaseReducer,
});
export interface TypedUseSelectorHook<TState> {
  <TSelected>(selector: (state: TState) => TSelected): TSelected;
}
export type RootState = ReturnType<typeof rootReducer>;
export const useSelector: TypedUseSelectorHook<RootState> = selected => {
  return rawUseSelector(selected, shallowEqual);
};

export default rootReducer;

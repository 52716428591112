import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type ErrorState = {
  hasError: boolean;
  error: Error;
};

const initialState: ErrorState = {
  hasError: false,
  error: null,
};

export const errorModule = createSlice({
  name: 'error',
  initialState,
  reducers: {
    catchError(state: ErrorState, action: PayloadAction<Error>) {
      state.hasError = true;
      state.error = action.payload;
    },
    clearError(state: ErrorState, action: PayloadAction<void>) {
      state.hasError = false;
      state.error = null;
    },
  },
});

export const { catchError, clearError } = errorModule.actions;

import React, { createContext, useContext, useMemo } from 'react';
import { useSelector } from '../../__share__/modules/reducer';
import {
  AssignWorkerSetting,
  AssignWorkerSettingModel,
  EventSetting,
  EventSettingModel,
  ISettingModel,
  OrganizationInfoSetting,
  OrganizationInfoSettingModel,
  SettingModel,
  SkillSetting,
  SkillSettingModel,
} from '../../__share__/modules/settings/model';
import { byName } from '../../__share__/modules/common/selectors';
import { AccountModel, IAccountModelHash } from '../../__share__/modules/accounts/model';
import { IWorkerModelHash, WorkerModel } from '../../__share__/modules/workers/model';
import { workerModelIdSelector } from '../../__share__/modules/workers/selectors';
import { accountModelWorkerIdSelector } from '../../__share__/modules/accounts/selectors';
import LoadingScreen from '../components/common/LoadingScreen';

type Props = {
  children: any;
};
type Profile = {
  email: string;
  license: string;
  workerId: string;
  organizationId: string;
  role: string;
  account: AccountModel;
  worker: WorkerModel;
};

const AuthContext = createContext(null);
const SettingContext = createContext(null);

export const useProfile = () => {
  const { profile, profileAccount, profileWorker } = useContext(AuthContext);
  profile.account = profileAccount;
  profile.worker = profileWorker;
  return profile as Profile;
};

export const useSetting = () => {
  const settings = useContext(SettingContext);
  const workerSetting = new SettingModel(byName(settings, AssignWorkerSetting.name)).getValueJson();
  const organizationInfo = new SettingModel(byName(settings, OrganizationInfoSetting.name)).getValueJson();
  const eventSetting = new SettingModel(byName(settings, EventSetting.name)).getValueJson();
  const skillSetting = new SettingModel(byName(settings, SkillSetting.name)).getValueJson();
  Object.keys(workerSetting).forEach(
    v => (workerSetting[v] = new AssignWorkerSettingModel(workerSetting[v]))
  );
  return {
    settings: settings.map(v => new SettingModel(v)),
    workerSetting,
    eventSetting: new EventSettingModel(eventSetting),
    organizationInfo: new OrganizationInfoSettingModel(organizationInfo),
    skillSetting: new SkillSettingModel(skillSetting),
  };
};

function AppContext(props: Props) {
  const { children } = props;
  const profile = useSelector(state => state.firebase.profile);
  const settings = useSelector<ISettingModel[]>(state => state.firestore.ordered.settings);
  const accountHash = useSelector<IAccountModelHash>(state => state.firestore.data.accounts);
  const workerHash = useSelector<IWorkerModelHash>(state => state.firestore.data.workers);

  const profileAccount = useMemo(
    () => accountModelWorkerIdSelector(accountHash, profile.workerId),
    [accountHash, profile.workerId]
  );

  const profileWorker = useMemo(
    () => workerModelIdSelector(workerHash, profile.workerId),
    [profile.workerId, workerHash]
  );

  if (!profile || !settings || !profileAccount || !profileWorker) return <LoadingScreen />;

  return (
    <AuthContext.Provider value={{ profile, profileAccount, profileWorker }}>
      <SettingContext.Provider value={settings}>{children}</SettingContext.Provider>
    </AuthContext.Provider>
  );
}

export default React.memo(AppContext);

import { useFirestoreConnect, isLoaded } from 'react-redux-firebase';
import LoadingScreen from '../components/common/LoadingScreen';
import { useSelector } from '../../__share__/modules/reducer';
import React from 'react';
import { IThreadModelHash } from '../../__share__/modules/threads/model';
import { IPatientModelHash } from '../../__share__/modules/patients/model';
import { IWorkerModelHash } from '../../__share__/modules/workers/model';
import { ICareserviceModelHash } from '../../__share__/modules/careservices/model';
import { ISettingModelHash } from '../../__share__/modules/settings/model';
import { IAccountModelHash } from '../../__share__/modules/accounts/model';
import { IJobModel } from '../../__share__/modules/jobs/model';
import { startDayOf } from '../../__share__/utils/dateUtil';

type Props = {
  children: any;
};

function FirebaseMasterConnector({ children }: Props) {
  const profile = useSelector(state => state.firebase.profile);
  const patients = useSelector<IPatientModelHash>(state => state.firestore.data.patients);
  const workers = useSelector<IWorkerModelHash>(state => state.firestore.data.workers);
  const careservices = useSelector<ICareserviceModelHash>(state => state.firestore.data.careservices);
  const settings = useSelector<ISettingModelHash>(state => state.firestore.data.settings);
  const threads = useSelector<IThreadModelHash>(state => state.firestore.data.threads);
  const accounts = useSelector<IAccountModelHash>(state => state.firestore.data.accounts);
  const jobs = useSelector<IJobModel[]>(state => state.firestore.data.jobs);

  useFirestoreConnect([
    {
      collection: `/accounts`,
      storeAs: 'accounts',
      limit: 10000,
      where: [['organizationId', '==', profile.organizationId]],
    },
    {
      collection: `/organizations/${profile.organizationId}/patients`,
      storeAs: 'patients',
      orderBy: ['lastNameKana', 'asc'],
      // where: [['isValid', '==', true]],
      limit: 10000,
    },
    {
      collection: `/organizations/${profile.organizationId}/workers`,
      storeAs: 'workers',
      orderBy: ['lastNameKana', 'asc'],
      // where: [['isValid', '==', true]],
      limit: 10000,
    },
    {
      collection: `/organizations/${profile.organizationId}/careservices`,
      storeAs: 'careservices',
      orderBy: ['title', 'asc'],
      limit: 10000,
    },
    {
      collection: `/organizations/${profile.organizationId}/settings`,
      storeAs: 'settings',
      limit: 10000,
    },
    {
      collection: `/organizations/${profile.organizationId}/assignpatients`,
      storeAs: 'assignpatients',
      where: [['isValid', '==', true]],
      limit: 10000,
    },
    {
      collection: `/organizations/${profile.organizationId}/threads`,
      storeAs: 'threads',
      where: [['workerIds', 'array-contains', profile.workerId]],
      orderBy: ['latestMessageDate', 'desc'],
    },
    {
      collection: `/organizations/${profile.organizationId}/jobs`,
      storeAs: 'jobs',
      orderBy: ['createDate', 'desc'],
      limit: 1,
    },
    // {
    //   collection: `/organizations/${profile.organizationId}/notifications`,
    //   storeAs: 'notifications',
    //   where: [['workerIds', 'array-contains', profile.workerId]],
    //   orderBy: ['createDate', 'desc'],
    //   limit: 30,
    // },
    {
      collection: `/organizations/${profile.organizationId}/notifications`,
      storeAs: 'notifications',
      where: [['workerIds', 'array-contains', profile.workerId]],
      orderBy: ['createDate', 'desc'],
      limit: 30,
    },
    {
      collection: `/organizations/${profile.organizationId}/workstatus`,
      storeAs: 'workstatus',
      where: [['targetDate', '>=', startDayOf(new Date())]],
      limit: 10000,
    },
  ]);

  if (
    !isLoaded(patients) ||
    !isLoaded(workers) ||
    !isLoaded(careservices) ||
    !isLoaded(settings) ||
    !isLoaded(threads) ||
    !isLoaded(threads) ||
    !isLoaded(jobs) ||
    !isLoaded(accounts)
  )
    return <LoadingScreen />;
  return children;
}
export default React.memo(FirebaseMasterConnector);

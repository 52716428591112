import { AUTH, PART_HELPER_NAV } from '../constants/auth';
import { Role } from '../constants/enums/role';

interface Param {
  targetId: string;
  ownerId: string;
}
interface Params {
  targetIds: string[];
  ownerId: string;
}

export interface UserRule {
  static?: string[];
  dynamic?: {
    [key: string]: Function;
  };
}

export interface Rule {
  [key: string]: UserRule;
}

export const rules: Rule = {
  MANAGER: {
    static: [
      AUTH.navbar.list,
      AUTH.dashboard.view,
      AUTH.account.view,
      AUTH.worker.edit,
      AUTH.patient.edit,
      AUTH.patient.viewCompatibility,
      AUTH.careplan.edit,
      AUTH.event.edit,
      AUTH.event.assign,
      AUTH.chat.showTel,
      AUTH.setting.edit,
      AUTH.report.edit,
      AUTH.report.editAssign,
      AUTH.setting.view,
      // サ責だけが持つ権限
      AUTH.task.submitAll,
      AUTH.setting.initData,
    ],
  },
  HELPER: {
    static: [
      AUTH.navbar.list,
      AUTH.dashboard.view,
      AUTH.account.view,
      AUTH.worker.edit,
      AUTH.patient.edit,
      AUTH.patient.viewCompatibility,
      AUTH.careplan.edit,
      AUTH.event.edit,
      AUTH.event.assign,
      AUTH.chat.showTel,
      AUTH.setting.edit,
      AUTH.report.edit,
      AUTH.report.editAssign,
      AUTH.setting.view,
    ],
  },
  PART_HELPER: {
    static: [],
    dynamic: {
      'navbar:list': ({ path }) => {
        if (Array.isArray(path)) {
          return path.some(v => PART_HELPER_NAV.includes(v));
        }
        return PART_HELPER_NAV.includes(path);
      },
      'worker:edit': ({ targetId, ownerId }: Param) => {
        if (!targetId || !ownerId) return false;
        return targetId === ownerId;
      },
      'report:edit': ({ targetIds, ownerId }: Params) => {
        if (!targetIds || !ownerId) return false;
        return targetIds.includes(ownerId);
      },
      'event:edit': ({ targetId, ownerId }: Param) => {
        if (!targetId || !ownerId) return false;
        return targetId === ownerId;
      },
      'task:submit': ({ targetIds, ownerId }: Params) => {
        if (!targetIds || !ownerId) return false;
        return targetIds.includes(ownerId);
      },
    },
  },
  REGISTER_HELPER: {
    static: [],
    dynamic: {
      'navbar:list': ({ path }) => {
        if (Array.isArray(path)) {
          return path.some(v => PART_HELPER_NAV.includes(v));
        }
        return PART_HELPER_NAV.includes(path);
      },
      'worker:edit': ({ targetId, ownerId }: Param) => {
        if (!targetId || !ownerId) return false;
        return targetId === ownerId;
      },
      'report:edit': ({ targetIds, ownerId }: Params) => {
        if (!targetIds || !ownerId) return false;
        return targetIds.includes(ownerId);
      },
      'event:edit': ({ targetId, ownerId }: Param) => {
        if (!targetId || !ownerId) return false;
        return targetId === ownerId;
      },
      'task:submit': ({ targetIds, ownerId }: Params) => {
        if (!targetIds || !ownerId) return false;
        return targetIds.includes(ownerId);
      },
    },
  },
};
export const check = (role: Role, action: string, data?: Object): boolean => {
  const permissions = rules[role.key];
  if (!permissions) return false;

  const staticPermissions = permissions.static;
  if (staticPermissions && staticPermissions.includes(action)) return true;

  const dynamicPermissions = permissions.dynamic;
  if (dynamicPermissions) {
    const permissionCondition = dynamicPermissions[action];
    if (!permissionCondition || !data) return false;
    return permissionCondition(data);
  }

  return false;
};

export type PageKind = { title: string; path: string };
export const PAGES = {
  top: { title: 'トップ', path: '/app' },
  dashboard: { title: 'ダッシュボード', path: '/app/dashboard' },
  worker: {
    list: { title: 'ヘルパー一覧', path: '/app/workers' },
    detail: { title: 'ヘルパー詳細', path: '/app/workers/:id/:tab' },
    detailTabs: {
      basic: { title: '基本情報', path: 'basic' },
      workingInfo: { title: '勤務情報', path: 'workingInfo' },
    },
    create: { title: 'ヘルパー作成', path: '/app/workers/create' },
    assign: {
      title: 'スケジュール割当て',
      path: '/app/schedule/assign/:yyyymmdd?/:viewParam?',
      basepath: '/app/schedule/assign',
    },
    schedule: {
      title: 'ヘルパースケジュール確認',
      path: '/app/workers/schedule/:id?/:yyyymmdd?/:viewParam?',
      basepath: '/app/workers/schedule',
    },
    scheduleAll: {
      title: '全ヘルパースケジュール確認',
      path: '/app/workers/schedule/all/:yyyymm?/:viewParam?',
      basepath: '/app/workers/schedule/all',
    },
    salary: {
      title: 'ヘルパー実績確認',
      path: '/app/workers/salary/:id?/:yyyymm?',
      basepath: '/app/workers/salary',
    },
  },
  patient: {
    list: { title: '利用者一覧', path: '/app/patients' },
    detail: { title: '利用者詳細', path: '/app/patients/:id/:tab' },
    detailTabs: {
      basic: { title: '基本情報', path: 'basic' },
      careplans: { title: '訪問計画', path: 'careplans' },
      report: { title: '履歴', path: 'reports' },
    },
    create: { title: '利用者作成', path: '/app/patients/create' },
    schedule: {
      title: '利用者スケジュール確認',
      path: '/app/patients/schedule/:id?/:yyyymmdd?/:viewParam?',
      basepath: '/app/patients/schedule',
    },
    scheduleAll: {
      title: '全利用者スケジュール確認',
      path: '/app/patients/schedule/all/:yyyymm?/:viewParam?',
      basepath: '/app/patients/schedule/all',
    },
  },
  setting: {
    detail: { title: '事業所設定', path: '/app/settings/:tab' },
    detailTabs: {
      basic: { title: '基本情報', path: 'basic' },
      careservices: { title: '提供サービス管理', path: 'careservices' },
      skills: { title: 'スキル・資格管理', path: 'skills' },
      // other: { title: 'その他設定', path: 'other' },
    },
    dataload: { title: 'データ管理', path: '/app/dataload' },
  },
  navigation: { title: 'ナビゲーション', path: '/app/navigation/:id?/:yyyymmdd?' },
  chat: { title: '連絡', path: '/app/chat' },
  careservice: {
    list: { title: '提供サービス一覧', path: '/app/careservices' },
  },
  account: {
    login: { title: 'ログイン', path: '/login' },
    list: { title: 'アカウント一覧', path: '/app/account/list' },
    edit: { title: 'アカウント情報修正', path: '/app/account/edit/:tab' },
    editTabs: {
      login: { title: 'ログイン情報', path: 'login' },
    },
  },
  error: {
    notFound: { title: '404エラー', path: '/404' },
    serviceUnavailable: { title: '503エラー', path: '/503' },
  },
};

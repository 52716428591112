import { ThreadModel } from './model';
import BaseService from '../common/_BaseService';
import firebase from 'firebase/app';

export class ThreadService extends BaseService {
  constructor() {
    super('threads');
  }

  trans2Model(id: string, data: firebase.firestore.DocumentData): ThreadModel {
    const model = Object.assign({}, data);
    const now = new Date();
    model.createDate = model.createDate ? model.createDate.toDate() : now;
    model.updateDate = model.updateDate ? model.updateDate.toDate() : now;
    model.latestMessageDate = model.latestMessageDate ? model.latestMessageDate.toDate() : '';
    return new ThreadModel({ id: id, ...model });
  }
}
// laze読み込みされないレイアウト内で使うため、AppDataHolder生成前となりコンストラクタで落ちる事象の対応
// const threadService = new ThreadService();
// export default threadService;

import React from 'react';

function Logo(props) {
  const { topbar = false } = props;
  return (
    <>
      {topbar ? (
        <img height="35px" alt="Logo" src="/static/logo.png" {...props} />
      ) : (
        <img alt="Logo" src="/static/logo.png" {...props} />
      )}
    </>
  );
}
export default React.memo(Logo);

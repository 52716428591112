import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import AssignmentIcon from '@material-ui/icons/Assignment';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import SettingsIcon from '@material-ui/icons/Settings';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import ModeCommentIcon from '@material-ui/icons/ModeComment';
import AssessmentIcon from '@material-ui/icons/Assessment';

export const navConfig = [
  {
    subheader: '業務',
    items: [
      {
        title: 'トップページ',
        iconTitle: 'トップ',
        href: '/app/dashboard',
        icon: AssessmentIcon,
        match: ['/app/dashboard'],
      },
      {
        title: '利用者スケジュール',
        iconTitle: '利用者',
        // href: '/app/patients/schedule',
        href: ['/app/patients/schedule', '/app/patients/schedule/all'],
        icon: CalendarTodayIcon,
        match: [
          '/app/patients/schedule',
          '/app/patients/schedule/:id/:yyyymmdd/:viewParam',
          '/app/patients/schedule/all',
          '/app/patients/schedule/all/:yyyymmdd/:viewParam',
        ],
        items: [
          {
            title: '個別スケジュール',
            href: '/app/patients/schedule',
            match: ['/app/patients/schedule/', '/app/patients/schedule/:id/:yyyymmdd/:viewParam'],
          },
          {
            title: '全体月次スケジュール',
            href: '/app/patients/schedule/all',
            match: ['/app/patients/schedule/all/:yyyymmdd/:viewParam'],
          },
        ],
      },
      {
        title: 'ヘルパースケジュール',
        iconTitle: 'ヘルパー',
        href: ['/app/workers/schedule', '/app/workers/schedule/all'],
        icon: CalendarTodayIcon,
        match: [
          '/app/workers/schedule',
          '/app/workers/schedule/:id/:yyyymmdd/:viewParam',
          '/app/workers/schedule/all',
          '/app/workers/schedule/all/:yyyymmdd/:viewParam',
        ],
        items: [
          {
            title: '個別スケジュール',
            href: '/app/workers/schedule',
            match: ['/app/workers/schedule/', '/app/workers/schedule/:id/:yyyymmdd/:viewParam'],
          },
          {
            title: '全体月次スケジュール',
            href: '/app/workers/schedule/all',
            match: ['/app/workers/schedule/all/:yyyymmdd/:viewParam'],
          },
        ],
      },
      {
        title: 'サービス割当て',
        iconTitle: '割当て',
        icon: AssignmentIndIcon,
        href: '/app/schedule/assign',
        match: ['/app/schedule/assign', '/app/schedule/assign/:yyyymmdd/:viewParam'],
        functionNotificationName: 'unassignedEventCount',
      },
      {
        title: 'ヘルパー連絡',
        iconTitle: '連絡',
        icon: ModeCommentIcon,
        href: '/app/chat',
        match: ['/app/chat'],
        functionNotificationName: 'unreadMessageCount',
      },
      {
        title: '訪問記録',
        iconTitle: '訪問記録',
        icon: AssignmentIcon,
        href: '/app/workers/salary',
        match: ['/app/workers/salary', '/app/workers/salary/:id/:yyyymm'],
      },
    ],
  },
  {
    subheader: '管理',
    items: [
      {
        title: 'ユーザ管理',
        iconTitle: '管理',
        icon: PeopleAltIcon,
        href: ['/app/patients', '/app/workers'], // auth checkで利用。子供要素のリンクの一覧
        match: [
          '/app/patients',
          '/app/patients/create',
          '/app/patients/:id/basic',
          '/app/patients/:id/careplans',
          '/app/patients/:id/schedule',
          '/app/patients/:id/reports',
          '/app/workers',
          '/app/workers/create',
          '/app/workers/:id/basic',
          '/app/workers/:id/skills',
        ],
        items: [
          {
            title: '利用者',
            href: '/app/patients',
            match: [
              '/app/patients',
              '/app/patients/create',
              '/app/patients/:id/basic',
              '/app/patients/:id/careplans',
              '/app/patients/:id/schedule',
              '/app/patients/:id/reports',
            ],
          },
          {
            title: 'ヘルパー',
            href: '/app/workers',
            match: [
              '/app/workers',
              '/app/workers/create',
              '/app/workers/:id/basic',
              '/app/workers/:id/skills',
            ],
          },
        ],
      },
      {
        title: '設定',
        iconTitle: '設定',
        icon: SettingsIcon,
        href: ['/app/settings/basic', '/app/careservices', '/app/account/list'], // auth checkで利用。子供要素のリンクの一覧
        match: [
          '/app/settings',
          '/app/settings/basic',
          '/app/settings/careservices',
          '/app/settings/skills',
          '/app/careservices',
          '/app/account/list',
          '/app/account/edit/:tab',
          '/app/dataload',
        ],
        items: [
          {
            title: '事業所設定',
            href: '/app/settings/basic',
            match: ['/app/settings/basic', '/app/settings/careservices', '/app/settings/skills'],
          },
          {
            title: 'アカウント',
            href: '/app/account/list',
            match: ['/app/account/list', '/app/account/edit/:tab'],
          },
          // {
          //   title: 'データ管理',
          //   href: '/app/dataload',
          //   match: ['/app/dataload'],
          // },
        ],
      },
    ],
  },
];

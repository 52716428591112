import BaseModel, { IBaseModel } from '../common/_BaseModel';
import { getBaseDateTime } from '../../utils/rruleUtil';

export type TransportationKind = 'WALK' | 'BIKE' | 'CAR';
export type SettingNames =
  | 'organizationInfo'
  | 'organizationLicense'
  | 'workingTime'
  | 'tokens'
  | 'constraints'
  | 'assign'
  | 'event'
  | 'service';

export type ISettingModelHash = { [key: string]: ISettingModel };
export type ISettingModel = IBaseModel & {
  group: string;
  name: SettingNames;
  valueJson: string;
};
export class SettingModel extends BaseModel implements ISettingModel {
  group: string = '';
  name: SettingNames = OrganizationInfoSetting.name;
  valueJson: string = '{}';

  constructor(param?: Partial<ISettingModel>) {
    super();
    Object.assign(this, param);
  }

  public getValueJson() {
    return JSON.parse(this.valueJson);
  }

  public setValueJson(value) {
    this.valueJson = JSON.stringify(value);
  }

  public toCsvDataObj() {
    return {
      group: this.group,
      name: this.name,
      valueJson: this.valueJson,
    };
  }

  public static fromCsvData(param?: Partial<ISettingModel>) {
    return new SettingModel(param);
  }
}
// 個別JSONのモデル
export type IConstraintsSetting = IBaseModel & {
  code: string;
  label: string;
  level: string;
};
export class ConstraintsSettingModel extends BaseModel implements IConstraintsSetting {
  //TODO code,name,title,key,labelがごっちゃごちゃ
  code: string = '';
  label: string = '';
  level: string = '';

  constructor(param?: Partial<ISettingModel>) {
    super();
    Object.assign(this, param);
  }
}

export type ILicenseSettingModel = {
  kind: string;
};
export class licenseSettingModel implements ILicenseSettingModel {
  kind: string = '';

  constructor(param?: Partial<ILicenseSettingModel>) {
    Object.assign(this, param);
  }
}

export const OrganizationInfoSetting = { group: 'common', name: 'organizationInfo' as SettingNames };
export type IOrganizationInfoSettingModel = {
  id: string;
  name: string;
  addressPrefecture: string;
  address1: string;
  address2: string;
  offerTime: { start: string; end: string };
  addressPoint: { latitude: number; longitude: number };
};
export class OrganizationInfoSettingModel implements IOrganizationInfoSettingModel {
  id: string = '';
  name: string = '';
  addressPrefecture: string = '';
  address1: string = '';
  address2: string = '';
  offerTime: { start: string; end: string } = { start: '08:00', end: '18:00' };
  addressPoint: { latitude: number; longitude: number } = { latitude: 0, longitude: 0 };

  constructor(param?: Partial<ISettingModel>) {
    Object.assign(this, param);
  }

  public getAddress(): string {
    return `${this.addressPrefecture} ${this.address1} ${this.address2}`;
  }
  public getGeoPointCsv(): string {
    return `${this.addressPoint.latitude},${this.addressPoint.longitude}`;
  }
  public getGeoPointObj() {
    return {
      lat: this.addressPoint.latitude,
      lng: this.addressPoint.longitude,
    };
  }
  public getOfferTimeString() {
    return `${this.offerTime.start} - ${this.offerTime.end}`;
  }
}
export const NotificationSetting = { group: 'common', name: 'notification' as SettingNames };
export type INotificationSettingModel = {
  enableTomorrowNotification: boolean;
  enableJustBeforeNotification: boolean;
};
export class NotificationSettingModel implements INotificationSettingModel {
  enableTomorrowNotification = true;
  enableJustBeforeNotification = true;
  constructor(param?: Partial<ISettingModel>) {
    Object.assign(this, param);
  }
}
export const WorkingTimeInfoSetting = { group: 'common', name: 'workingTime' as SettingNames };
export type IWorkingTimeInfoSettingModel = {
  earlyStart: Date;
  daytimeStart: Date;
  nighttimeStart: Date;
  midnightStart: Date;
};
export class WorkingTimeInfoSettingModel implements IWorkingTimeInfoSettingModel {
  earlyStart: Date = getBaseDateTime(6, 0);
  daytimeStart: Date = getBaseDateTime(8, 0);
  nighttimeStart: Date = getBaseDateTime(18, 0);
  midnightStart: Date = getBaseDateTime(22, 0);
  constructor(param?: Partial<ISettingModel>) {
    Object.assign(this, param);
  }
}

export const EventSetting = { group: 'assign', name: 'event' as SettingNames };
export type IEventSettingModel = {
  showFirstTime: boolean;
};
export class EventSettingModel implements IEventSettingModel {
  showFirstTime: boolean = true;
  constructor(param?: Partial<IEventSettingModel>) {
    Object.assign(this, param);
  }
}

export const AssignWorkerSetting = { group: 'assign', name: 'assign' as SettingNames };
export type AssignWorkerSettingModelHash = { [key: string]: AssignWorkerSettingModel };
export type IAssignWorkerSettingModel = {
  visible: boolean;
};
export class AssignWorkerSettingModel implements IAssignWorkerSettingModel {
  visible: boolean = true;

  constructor(param?: Partial<ISettingModel>) {
    Object.assign(this, param);
  }

  public static isVisible(hash: AssignWorkerSettingModelHash, id: string): boolean {
    // ハッシュになければ許可
    return !hash[id] || hash[id].visible;
  }
}

// skill
export const SkillSetting = { group: 'common', name: 'skill' as SettingNames };
export type ISkillSettingModel = {
  lastIndex: number;
  kind: { [key: string]: string };
};
export type ISkill = { index: string; title: string };
export class SkillSettingModel implements ISkillSettingModel {
  lastIndex: number = 0;
  kind: { [key: string]: string } = {};
  constructor(param?: Partial<ISkillSettingModel>) {
    Object.assign(this, param);
  }
  addSkill(title: string) {
    this.lastIndex++;
    this.kind[`${this.lastIndex}`] = title;
  }
  editSkill(index: string, title: string) {
    this.kind[index] = title;
  }
  getArray(): ISkill[] {
    return Object.entries(this.kind).map(([k, v]) => {
      return { index: k, title: v };
    });
  }
  getIndexes(): string[] {
    return Object.keys(this.kind);
  }
  getOption() {
    return Object.entries(this.kind).map(([k, v]) => {
      return { key: k, value: k, label: v };
    });
  }
  getLabelString(keys: string[]): string {
    if (!keys) return 'なし';
    return keys.length === 0 ? 'なし' : keys.map(key => this.kind[key]).join(',');
  }
}

import { AccountModel } from './model';
// import { fireStore } from '../../config/fbConfig';
import AppDataHolder from '../common/appDataHolder';
import { OptimisticError } from '../error/errors';
import { fireStore } from '../common/_BaseService';
import firebase from 'firebase/app';

export class AccountService {
  public ref: firebase.firestore.CollectionReference;
  public userId: string;
  public organizationId: string;

  constructor() {
    // serviceを利用してデータ更新するユーザは、必ず認証済み(かつAuthRoute以下のコンポーネントから実行)である前提
    this.ref = fireStore.collection('accounts');
    this.userId = AppDataHolder.getInstance().getWorkerId();
    this.organizationId = AppDataHolder.getInstance().getOrganizationId();
  }

  public getById = async (id: string) => {
    let model = await this.ref.doc(id).get({ source: 'server' });
    return this.trans2Model(model.id, model.data());
  };

  public add = async (model: AccountModel) => {
    model.updateDate = new Date();
    model.createDate = new Date();
    model.updateUser = this.userId;
    model.organizationId = this.organizationId;
    const ret = await this.ref.add(model.toFirestoreCreateDocument());
    model.id = ret.id;
    return model;
  };

  public update = async (model: AccountModel) => {
    const latestData = await this.getById(model.id);
    if (latestData.updateDate && latestData.updateDate.getTime() !== model.updateDate.getTime()) {
      throw new OptimisticError();
    }
    // データ取得時からデータに変更がある場合にはエラーにする
    model.updateDate = new Date();
    model.updateUser = this.userId;
    return await this.ref.doc(model.id).update(model.toFirestoreUpdateDocument());
  };

  public delete = async (id: string) => {
    return await this.ref.doc(id).delete();
  };

  trans2Model(id: string, data: firebase.firestore.DocumentData): AccountModel {
    const model = Object.assign({}, data);
    const now = new Date();
    model.createDate = model.createDate ? model.createDate.toDate() : now;
    model.updateDate = model.updateDate ? model.updateDate.toDate() : now;
    return new AccountModel({ id: id, ...model });
  }
}

/* eslint-disable no-console */
/* eslint-disable import/prefer-default-export */
import { colors, createMuiTheme, Theme } from '@material-ui/core';
import typography from './typography';
import { softShadows } from './shadows';

export type CustomTheme = Theme & {
  palette: {
    action: {
      active: any;
    };
    background: {
      default: any;
      deep: any;
      gray: any;
      paper: any;
    };
    primary: {
      main: any;
      light: any;
      veryLight: any;
    };
    secondary: {
      main: any;
    };
    tertiary: {
      main: any;
      light: any;
    };
  };
};
export const baseStyleConfig: any = {
  typography,
  overrides: {
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: 'hidden',
      },
    },
    MuiInputBase: {
      input: {
        '&::placeholder': {
          opacity: 1,
          color: colors.blueGrey[600],
        },
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32,
      },
    },
    MuiChip: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.075)',
      },
    },
  },
  palette: {
    action: {
      active: '#676F74',
    },
    background: {
      default: colors.common.white,
      deep: '#676F74',
      gray: '#f4f6f8',
      paper: colors.common.white,
    },
    primary: {
      heavy: '#31326f',
      main: '#9ddfd3',
      light: '#afe5ec',
      veryLight: '#dbf6e9',
    },
    secondary: {
      heavy: '#F97170',
      main: '#F99695',
      light: '#ffc1c1',
    },
    tertiary: {
      main: '#fef7d5',
      light: '#fefae5',
    },
    text: {
      primary: colors.grey[900],
      secondary: colors.grey[600],
      link: '#F97170',
    },
  },
  shadows: softShadows,
};

export function createTheme() {
  return createMuiTheme(baseStyleConfig);
}

// 想定外例外（エラーページ遷移）
export class RuntimeError extends Error {
  constructor(e?: string, private retry: number = 0) {
    super(e);
    this.name = new.target.name;
    Object.setPrototypeOf(this, new.target.prototype);
  }
  toJSON() {
    return { retry: this.retry };
  }
}
// 想定例外（エラーメッセージ出力して処理継続）
export class BusinessError extends RuntimeError {}
export class OptimisticError extends BusinessError {}

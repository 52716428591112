import { BaseConstants } from './_BaseConstants';

export class Role extends BaseConstants {
  static readonly MANAGER = new Role('MANAGER', '社員（サービス提供責任者）');
  static readonly HELPER = new Role('HELPER', '社員（ヘルパー）');
  static readonly PART_HELPER = new Role('PART_HELPER', 'パートヘルパー');
  static readonly REGISTER_HELPER = new Role('REGISTER_HELPER', '登録ヘルパー');

  private constructor(public readonly key: string, public readonly label: string) {
    super();
  }

  toString() {
    return this.key;
  }
}

import React, { useState, useRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import {
  Theme,
  Button,
  Collapse,
  ListItem,
  makeStyles,
  Badge,
  Menu,
  MenuItem,
  ListItemText,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

const useStyles = makeStyles((theme: Theme) => ({
  menu: {
    width: 256,
    maxWidth: '100%',
  },
  item: {
    display: 'block',
    paddingTop: 0,
    paddingBottom: 0,
  },
  itemLeaf: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    color: theme.palette.text.secondary,
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
  },
  buttonOnlyLeaf: {
    color: theme.palette.text.secondary,
    textTransform: 'none',
    letterSpacing: 0,
    // fontWeight: theme.typography.fontWeightRegular,
  },
  buttonLeaf: {
    color: theme.palette.text.secondary,
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    // fontWeight: theme.typography.fontWeightRegular,
    '&.depth-0': {
      '& $title': {
        fontWeight: 500,
      },
    },
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
    color: theme.palette.common.white,
  },
  onlyIcon: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.common.white,
  },
  title: {
    marginRight: 'auto',
    color: theme.palette.common.white,
  },
  active: {
    color: theme.palette.secondary.main,
    '& $title': {
      fontWeight: theme.typography.fontWeightMedium,
      color: theme.palette.secondary.light,
    },
    '& $onlyTitle': {
      color: theme.palette.secondary.light,
    },
    '& $icon': {
      color: theme.palette.secondary.light,
    },
    '& $onlyIcon': {
      color: theme.palette.secondary.light,
    },
  },
  onlyTitle: {},
  iconButtonLabel: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: 12,
    whiteSpace: 'normal',
    verticalAlign: 'middle',
    textAlign: 'center',
    // wordWrap: 'break-word',
    color: theme.palette.common.white,
  },
}));

type Props = {
  children?: any;
  className?: string;
  depth: number;
  href?: string;
  icon?: any;
  info?: any;
  open?: boolean;
  item: any;
  linkOpen?: boolean;
  title?: string;
  iconTitle?: string;
  functionNotifications: any;
};
function NavItem(props: Props) {
  const {
    title,
    href,
    depth,
    children,
    className,
    open,
    item,
    iconTitle,
    icon: Icon,
    linkOpen: openProp = false,
    info: Info,
    functionNotifications,
    ...rest
  } = props;
  const classes = useStyles();
  const moreRef = useRef(null);
  const [linkOpen, setLinkOpen] = useState(openProp);
  const [openMenu, setOpenMenu] = useState(false);

  const handleToggle = () => {
    setLinkOpen(prevOpen => !prevOpen);
  };
  const handleMenuOpen = () => {
    setOpenMenu(true);
  };

  const handleMenuClose = () => {
    setOpenMenu(false);
  };

  let paddingLeft = 8;
  if (depth > 0) {
    paddingLeft = 30 + 4 * depth;
  }
  const functionNotificationName = item['functionNotificationName'] ? item['functionNotificationName'] : '';
  const style = { paddingLeft };
  const buttonOnlyClassName = openProp
    ? clsx(classes.buttonOnlyLeaf, classes.active)
    : clsx(classes.buttonOnlyLeaf);
  const buttonClassName = openProp
    ? clsx(classes.buttonLeaf, `depth-${depth}`, classes.active)
    : clsx(classes.buttonLeaf, `depth-${depth}`);

  if (open) {
    // 拡大状態
    if (item.items) {
      return (
        <ListItem className={clsx(classes.item, className)} disableGutters key={title} {...rest}>
          <Button className={classes.button} onClick={handleToggle} style={style}>
            {Icon && <Icon className={classes.icon} size="20" />}
            <span className={classes.title}>{title}</span>
            {linkOpen ? <ExpandLessIcon color="primary" /> : <ExpandMoreIcon color="primary" />}
          </Button>
          <Collapse in={linkOpen}>{children}</Collapse>
        </ListItem>
      );
    } else {
      return (
        <ListItem className={clsx(classes.itemLeaf, className)} disableGutters key={title} {...rest}>
          <Button className={buttonClassName} component={RouterLink} exact style={style} to={href}>
            {Icon && (
              <Badge
                badgeContent={
                  functionNotifications[functionNotificationName] > 0
                    ? functionNotifications[functionNotificationName]
                    : null
                }
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                color="primary"
              >
                {/* variant="dot" > */}
                <Icon className={classes.icon} size="20" />
              </Badge>
            )}
            <span className={classes.title}>{title}</span>
            {Info && <Info />}
          </Button>
        </ListItem>
      );
    }
  } else {
    //　閉じてる状態
    if (item.items) {
      return (
        <ListItem className={clsx(classes.itemLeaf, className)} disableGutters key={iconTitle} {...rest}>
          <Button
            className={buttonOnlyClassName}
            classes={{ label: classes.iconButtonLabel }}
            onClick={handleMenuOpen}
            ref={moreRef}
          >
            {Icon && <Icon className={classes.onlyIcon} size="20" />}
            <div className={classes.onlyTitle}>{iconTitle}</div>
          </Button>
          <Menu
            anchorEl={moreRef.current}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            onClose={handleMenuClose}
            open={openMenu}
            PaperProps={{ className: classes.menu }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            {item.items.map(v => (
              <MenuItem key={v.href} component={RouterLink} exact to={v.href} onClick={handleMenuClose}>
                <ListItemText primary={v.title} />
              </MenuItem>
            ))}
          </Menu>
        </ListItem>
      );
    } else {
      return (
        <ListItem className={clsx(classes.itemLeaf, className)} disableGutters key={iconTitle} {...rest}>
          <Button
            className={buttonOnlyClassName}
            classes={{ label: classes.iconButtonLabel }}
            component={RouterLink}
            exact
            to={href}
          >
            {Icon && (
              <Badge
                badgeContent={
                  functionNotifications[functionNotificationName] > 0
                    ? functionNotifications[functionNotificationName]
                    : null
                }
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                color="primary"
              >
                <Icon className={classes.onlyIcon} size="20" />
              </Badge>
            )}
            <div className={classes.onlyTitle}>{iconTitle}</div>
          </Button>
        </ListItem>
      );
    }
  }
}
export default React.memo(NavItem);

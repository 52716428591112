import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICareplanModel, ICareplanModelHash } from './model';

export type CareplanState = {
  careplans: ICareplanModel[];
  careplanHash: ICareplanModelHash;
};

const initialState: CareplanState = {
  careplans: [],
  careplanHash: {},
};

export const careplanModule = createSlice({
  name: 'careplan',
  initialState,
  reducers: {
    clear(state: CareplanState, action: PayloadAction<void>) {
      state.careplanHash = {};
      state.careplans = [];
    },
    get(state: CareplanState, action: PayloadAction<ICareplanModel[]>) {
      state.careplanHash = action.payload.reduce((a, v) => {
        a[v.id] = v;
        return a;
      }, {});
      state.careplans = [...action.payload];
    },
    create(state: CareplanState, action: PayloadAction<ICareplanModel>) {
      const newStateHash = { ...state.careplanHash };
      newStateHash[action.payload.id] = action.payload;
      state.careplanHash = newStateHash;
      state.careplans = [...state.careplans, action.payload];
    },
    update(state: CareplanState, action: PayloadAction<ICareplanModel>) {
      const newStateHash = { ...state.careplanHash };
      newStateHash[action.payload.id] = action.payload;
      state.careplanHash = newStateHash;
      state.careplans = [...state.careplans.filter(v => v.id !== action.payload.id), action.payload];
    },
    bulkCreate(state: CareplanState, action: PayloadAction<ICareplanModel[]>) {
      const newStateHash = { ...state.careplanHash };
      const newCareplanIds = action.payload.map(v => v.id);
      action.payload.forEach(v => {
        newStateHash[v.id] = v;
      });
      state.careplanHash = newStateHash;
      state.careplans = [...state.careplans.filter(v => !newCareplanIds.includes(v.id)), ...action.payload];
    },
    bulkUpdate(state: CareplanState, action: PayloadAction<ICareplanModel[]>) {
      const newStateHash = { ...state.careplanHash };
      const newCareplanIds = action.payload.map(v => v.id);
      action.payload.forEach(v => {
        newStateHash[v.id] = v;
      });
      state.careplanHash = newStateHash;
      state.careplans = [...state.careplans.filter(v => !newCareplanIds.includes(v.id)), ...action.payload];
    },
    remove(state: CareplanState, action: PayloadAction<string>) {
      const newStateHash = { ...state.careplanHash };
      delete newStateHash[action.payload];
      state.careplanHash = newStateHash;
      state.careplans = [...state.careplans.filter(v => v.id !== action.payload)];
    },
  },
});

export const { get, create, update, remove } = careplanModule.actions;

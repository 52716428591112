import BaseModel from './_BaseModel';
import AppDataHolder from './appDataHolder';
import { OptimisticError } from '../error/errors';
import { MAX_FIRESTORE_BATCH_SIZE } from '../../constants/constants';

import firebase from 'firebase/app';
import 'firebase/firestore';

export const fireStore = firebase.firestore();
if (process.env.NODE_ENV === 'development') {
  // firebase.firestore().useEmulator('127.0.0.1', 8081);
  // firebase.auth().useEmulator('http://127.0.0.1:9099/');
}
// https://blog.skylarking.me/2019/04/12/data_pick_from_firestore/
export default abstract class BaseService {
  public ref: firebase.firestore.CollectionReference;
  public name: string;
  public userId: string;

  constructor(name: string) {
    // serviceを利用してデータ更新するユーザは、必ず認証済み(かつAuthRoute以下のコンポーネントから実行)である前提
    this.name = name;
    if (AppDataHolder.getInstance().isLoaded()) {
      this.ref = fireStore.collection(`${AppDataHolder.getInstance().getDbPath()}/${name}`);
      this.userId = AppDataHolder.getInstance().getWorkerId();
      // console.log(`${AppDataHolder.getInstance().getDbPath()}/${name} :: ${this.userId}`);
    } else {
      console.error('AppDataHolder is empty');
    }
  }
  public exists = async (id: string): Promise<boolean> => {
    const snap = await this.ref.doc(id).get({ source: 'server' });
    console.log(snap.exists);
    return snap.exists;
  };

  public get = async (sortKey?: string): Promise<any[]> => {
    const docRef = sortKey ? this.ref.orderBy(sortKey) : this.ref;
    let snap;
    try {
      // snap = await docRef.get({ source: "cache" });
      snap = await docRef.get({ source: 'server' });
    } catch (e) {
      snap = await docRef.get({ source: 'server' });
      console.error(e);
    }
    const result = [];
    snap.docs.forEach(model => {
      result.push(this.trans2Model(model.id, model.data()));
    });
    return result;
  };

  public getById = async (id: string): Promise<any> => {
    let model = await this.ref.doc(id).get({ source: 'server' });
    return this.trans2Model(model.id, model.data());
  };

  public upsert = async (model: BaseModel): Promise<any> => {
    if (!model) return;
    if (model.id) {
      return this.update(model);
    } else {
      return this.add(model);
    }
  };

  public upsertNoCheck = async (model: BaseModel): Promise<any> => {
    if (!model) return;
    if (model.id) {
      return this.updateNoCheck(model);
    } else {
      return this.add(model);
    }
  };

  public add = async (model: BaseModel): Promise<any> => {
    model.updateUser = this.userId;
    model.version = 0;
    const ret = await this.ref.add(model.toFirestoreCreateDocument());
    model.id = ret.id;
    return model;
  };

  public addSubCollection = async (
    parentRef: firebase.firestore.CollectionReference,
    parentId: string,
    model: BaseModel
  ): Promise<BaseModel> => {
    model.updateUser = this.userId;
    model.version = 0;
    const ret = await parentRef.doc(parentId).collection(this.name).add(model.toFirestoreCreateDocument());
    model.id = ret.id;
    return model;
  };

  public bulkCreateSubCollection = async (
    parentRef: firebase.firestore.CollectionReference,
    models: { parentId: string; model: BaseModel }[]
  ): Promise<BaseModel[]> => {
    if (!models) return null;
    let batch = fireStore.batch();
    const updateUser = this.userId;

    models.forEach((v, i) => {
      const newDocRef = parentRef.doc(v.parentId).collection(this.name).doc();
      v.model.updateUser = updateUser;
      v.model.id = newDocRef.id;
      v.model.version = 0;
      if ((i + 1) % MAX_FIRESTORE_BATCH_SIZE === 0) {
        batch.commit();
        batch = fireStore.batch();
      }
      batch.set(newDocRef, v.model.toFirestoreCreateDocument());
    });
    await batch.commit();
    return models.map(v => v.model);
  };

  public bulkCreate = async (models: BaseModel[]): Promise<any[]> => {
    if (!models) return null;
    const updateUser = this.userId;
    let batch = fireStore.batch();
    models.forEach((v, i) => {
      const newDocRef = this.ref.doc();
      v.updateUser = updateUser;
      v.id = newDocRef.id;
      v.version = 0;
      if ((i + 1) % MAX_FIRESTORE_BATCH_SIZE === 0) {
        batch.commit();
        batch = fireStore.batch();
      }
      batch.set(newDocRef, v.toFirestoreCreateDocument());
    });
    batch.commit();
    return models;
  };

  public update = async (model: BaseModel): Promise<any> => {
    // const upTime = firestore.FieldValue.serverTimestamp();
    const latestData = await this.getById(model.id);
    if (latestData.version !== model.version) {
      throw new OptimisticError();
    }
    // データ取得時からデータに変更がある場合にはエラーにする
    model.updateUser = this.userId;
    model.version = model.version != null ? model.version + 1 : 0;
    await this.ref.doc(model.id).update(model.toFirestoreUpdateDocument());
    return model;
  };

  public bulkUpdate = async (models: BaseModel[]): Promise<any[]> => {
    if (!models) return null;
    let batch = fireStore.batch();
    const updateUser = this.userId;
    models.forEach((v, i) => {
      v.updateUser = updateUser;
      v.version = v.version != null ? v.version + 1 : 0;
      //500件毎にcommitしてbatchインスタンスを初期化
      if ((i + 1) % MAX_FIRESTORE_BATCH_SIZE === 0) {
        batch.commit();
        batch = fireStore.batch();
      }
      batch.update(this.ref.doc(v.id), v.toFirestoreUpdateDocument());
    });
    batch.commit();
    return models;
  };

  public updateNoCheck = async (model: BaseModel): Promise<any> => {
    model.updateUser = this.userId;
    model.version = model.version != null ? model.version + 1 : 0;
    await this.ref.doc(model.id).update(model.toFirestoreUpdateDocument());
    return model;
  };

  public updateSubCollection = async (
    parentRef: firebase.firestore.CollectionReference,
    parentId: string,
    model: BaseModel
  ): Promise<BaseModel> => {
    model.updateUser = this.userId;
    model.version = model.version != null ? model.version + 1 : 0;
    await parentRef
      .doc(parentId)
      .collection(this.name)
      .doc(model.id)
      .update(model.toFirestoreUpdateDocument());
    console.log(model);

    return model;
  };

  public delete = async (id: string) => {
    return await this.ref.doc(id).delete();
  };

  public deleteSubCollection = async (
    parentRef: firebase.firestore.CollectionReference,
    parentId: string,
    id: string
  ): Promise<void> => {
    return await parentRef.doc(parentId).collection(this.name).doc(id).delete();
  };

  abstract trans2Model(id: string, data: firebase.firestore.DocumentData): any;
}

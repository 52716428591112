// import { useSelector } from '../../../../__share__/modules/reducer';
import React, { useMemo } from 'react';
import { useSelector } from '../../../../__share__/modules/reducer';
import { IThreadModel } from '../../../../__share__/modules/threads/model';
import { allNotReadCountSelector } from '../../../../__share__/modules/threads/selectors';
import { useProfile } from '../../../app/AppContext';
// import { allNotReadCountSelector } from '../../../../__share__/modules/threads/selectors';
import NavBar from './NavBar';
// import { IThreadModel } from '../../../../__share__/modules/threads/model';
// import { useProfile } from '../../../app/AppContext';

export interface ContainerProps {
  open: boolean;
  toggleDrawerOpen: any;
  functionNotifications?: any;
}

function NavBarContainer(props: ContainerProps) {
  const profile = useProfile();

  // const eventHash = useSelector<IEventModelHash>(state => state.firestore.data.events_today);
  // const careplanHash = useSelector<ICareplanModelHash>(state => state.firestore.data.careplans);
  const threads = useSelector<IThreadModel[]>(state => state.firestore.ordered.threads);

  const unreadMessageCount = useMemo(() => allNotReadCountSelector(threads, profile.workerId), [
    profile.workerId,
    threads,
  ]);
  // const unassignedEventCount = useMemo(() => unassignedEventCountSelector(eventHash), [eventHash]);
  // const unassignedCareplanCount = useMemo(() => unassignedCareplanCountSelector(careplanHash), [
  //   careplanHash,
  // ]);
  // const functionNotifications = {
  //   unreadMessageCount,
  //   unassignedEventCount: unassignedEventCount + unassignedCareplanCount,
  // };

  const functionNotifications = useMemo(() => {
    return {
      unreadMessageCount,
    };
  }, [unreadMessageCount]);

  const _props: ContainerProps = {
    functionNotifications,
    ...props,
  };
  return <NavBar {..._props} />;
}
export default React.memo(NavBarContainer);

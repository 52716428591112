export class BaseConstants {
  public readonly key: string;
  public readonly label: string;

  toString() {
    return this.label;
  }

  static getAll() {
    const val = Object.assign({}, this);
    return Object.entries(val).map(([k, v]) => v);
  }

  static getAllForOption() {
    return this.getAll().map(v => {
      return { value: v.key, label: v.label };
    });
  }

  static getByKey(key: string) {
    return this.getAll().find(v => v.key === key);
  }
}

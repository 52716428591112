import React from 'react';
import {
  Box,
  Button,
  Container,
  Typography,
  useTheme,
  useMediaQuery,
  makeStyles,
  Theme,
} from '@material-ui/core';
import Page from '../components/common/Page';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(3),
    paddingTop: 80,
    paddingBottom: 80,
  },
  image: {
    maxWidth: '100%',
    width: 560,
    maxHeight: 300,
    height: 'auto',
  },
}));

function Error503View() {
  const classes = useStyles();
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));

  const onClick = () => {
    window.location.href = '/';
  };
  return (
    <Page className={classes.root} title="503エラー">
      <Container maxWidth="lg">
        <Typography align="center" variant={mobileDevice ? 'h4' : 'h1'} color="textPrimary">
          現在、サービスが利用できません
        </Typography>
        <Typography align="center" variant="subtitle2" color="textSecondary">
          ご迷惑おかけして申し訳ありません。お時間をおいて再度お試しください
        </Typography>
        <Box mt={6} display="flex" justifyContent="center">
          <img alt="Under development" className={classes.image} src="/static/logo.png" />
        </Box>
        <Box mt={6} display="flex" justifyContent="center">
          <Button color="secondary" onClick={onClick} variant="contained">
            ホームに戻る
          </Button>
        </Box>
      </Container>
    </Page>
  );
}

export default Error503View;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type AuthState = {
  isSignedIn: boolean;
};

const initialState: AuthState = {
  isSignedIn: false,
};
// mobile appでのみ使用
export const authModule = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login(state: AuthState, action: PayloadAction<void>) {
      state.isSignedIn = true;
    },
    logout(state: AuthState, action: PayloadAction<void>) {
      state.isSignedIn = false;
    },
  },
});

export const { login, logout } = authModule.actions;

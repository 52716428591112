import React, { useCallback } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TopBar from './TopBar';
import NavBar from './NavBar';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.default,
      display: 'flex',
      height: '100%',
      overflow: 'hidden',
      width: '100%',
    },
    wrapper: {
      display: 'flex',
      flex: '1 1 auto',
      overflow: 'hidden',
      paddingTop: 64,
    },
    contentContainer: {
      display: 'flex',
      flex: '1 1 auto',
      overflow: 'hidden',
    },
    content: {
      flex: '1 1 auto',
      height: '100%',
      overflow: 'auto',
    },
  })
);

function AppLayout({ children }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const toggleDrawerOpen = useCallback(() => {
    setOpen(!open);
  }, [open]);

  return (
    <div className={classes.root}>
      <TopBar open={open} toggleDrawerOpen={toggleDrawerOpen} />
      <NavBar open={open} toggleDrawerOpen={toggleDrawerOpen} />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>{children}</div>
        </div>
      </div>
    </div>
  );
}
export default React.memo(AppLayout);

import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { AppBar, Box, Toolbar, makeStyles } from '@material-ui/core';
import Logo from '../../../components/common/Logo';
import Account from './Account';
import { CustomTheme } from '../../../../theme';

const drawerWidth = 240;

const useStyles = makeStyles((theme: CustomTheme) => ({
  toolbar: {
    height: 64,
  },

  root: {
    zIndex: 1000,
    boxShadow: 'none',
    backgroundColor: theme.palette.primary.veryLight,
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  logoClose: {
    position: 'absolute',
    left: theme.spacing(8),
  },
  logoOpen: {
    position: 'absolute',
    left: theme.spacing(8),
  },
}));

type Props = {
  className?: string;
  open: boolean;
  toggleDrawerOpen?: any;
};

function TopBar(props: Props) {
  const { open } = props;
  const classes = useStyles();

  return (
    <AppBar position="fixed" className={classes.root}>
      <Toolbar style={{ position: 'relative' }}>
        <RouterLink
          to="/"
          className={{
            [classes.logoOpen]: open,
            [classes.logoClose]: !open,
          }}
        >
          <Logo topbar={'true'} />
        </RouterLink>
        <Box ml={2} flexGrow={1} />
        {/* <NotificationsContainer /> */}
        <Box ml={2}>
          <Account />
        </Box>
      </Toolbar>
    </AppBar>
  );
}
export default React.memo(TopBar);

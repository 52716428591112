import firebase from 'firebase/app';
import 'firebase/auth';

class AuthService {
  isLoggedIn = () => {
    const user = firebase.auth().currentUser;
    return user !== null;
  };

  getCurrentUser = () => {
    return firebase.auth().currentUser;
  };

  reauthenticate = async (password: string) => {
    const user = firebase.auth().currentUser;
    const credential = firebase.auth.EmailAuthProvider.credential(user.email, password);
    return await user.reauthenticateWithCredential(credential);
  };

  loginWithEmailAndPassword = async (email: string, password: string) => {
    return await firebase.auth().signInWithEmailAndPassword(email, password);
  };

  resetPassword = (email: string) => {
    return firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(function () {})
      .catch(function (error) {
        return Promise.reject(error);
      });
  };

  changePassword = async (newPassword: string) => {
    return await firebase.auth().currentUser.updatePassword(newPassword);
  };

  createMailAccount = email => {
    return firebase
      .auth()
      .sendSignInLinkToEmail(email, {
        url: 'https://exp.curae.jp/',
        handleCodeInApp: true,
      })
      .then(function () {
        console.log('success');
      })
      .catch(function (error) {
        console.log(error);
        return Promise.reject(error);
      });
  };
  createAccountRequest = email => {
    return firebase
      .auth()
      .createUserWithEmailAndPassword(email, 'initialpassword')
      .then(function () {
        console.log('create');
      })
      .catch(function (error) {
        return Promise.reject(error);
      });
  };
  existsAccount = email => {
    return firebase
      .auth()
      .fetchSignInMethodsForEmail(email)
      .then(v => {
        if (v.findIndex(p => p === firebase.auth.EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD) !== -1) {
          return true;
        }
        return false;
      });
  };

  logout = () => {
    firebase.auth().signOut();
  };
}

const authService = new AuthService();
export default authService;

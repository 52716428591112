import { WorkerModel } from './model';
import BaseService, { fireStore } from '../common/_BaseService';
import { getLatLng } from '../../utils/googleMapUtil';
import firebase from 'firebase/app';

export class WorkerService extends BaseService {
  constructor() {
    super('workers');
  }

  public bulkCreateWithLatLng = async (models: WorkerModel[]): Promise<any[]> => {
    if (!models) return null;
    const updateUser = this.userId;
    const batch = fireStore.batch();

    await Promise.all(models.filter(v => v.isGeoPointEmpty()).map(async v => await this.setLatLng(v)));
    models.forEach(model => {
      // 住所検索APIから緯度経度を取得する
      const newDocRef = this.ref.doc();
      model.updateUser = updateUser;
      model.id = newDocRef.id;
      model.version = 0;
      batch.set(newDocRef, model.toFirestoreCreateDocument());
    });
    await batch.commit();
    return models;
  };
  private setLatLng = async (model: WorkerModel) => {
    const { lat, lng } = await getLatLng(`${model.address1}${model.address2}`);
    model.addressPoint = { latitude: lat, longitude: lng };
  };

  public addWithLatLng = async (model: WorkerModel) => {
    // 住所検索APIから緯度経度を取得する
    const { lat, lng } = await getLatLng(`${model.address1}${model.address2}`);
    model.addressPoint = { latitude: lat, longitude: lng };
    return await this.add(model);
  };

  public updateWithLatLng = async (model: WorkerModel) => {
    // 住所が変更された場合はAPI呼び出しを行い緯度経度を再取得。関連するrouteも全て削除する
    const latestData = await this.getById(model.id);
    if (
      latestData.address1 === model.address1 &&
      latestData.address2 === model.address2 &&
      latestData.addressPoint.latitude !== 0
    ) {
      return await this.update(model);
    }
    // 住所検索APIから緯度経度を取得する
    const { lat, lng } = await getLatLng(`${model.address1}${model.address2}`);
    model.addressPoint = { latitude: lat, longitude: lng };
    return await this.update(model);
  };

  trans2Model(id: string, data: firebase.firestore.DocumentData): WorkerModel {
    const model = Object.assign({}, data);
    const now = new Date();
    model.createDate = model.createDate ? model.createDate.toDate() : now;
    model.updateDate = model.updateDate ? model.updateDate.toDate() : now;
    model.birthday = model.birthday ? model.birthday.toDate() : '';
    model.locStatus = model.locStatus.map(v => {
      return { time: v.time.toDate(), patientId: v.patientId, status: v.status };
    });
    return new WorkerModel({ id: id, ...model });
  }
}

import { RRule, RRuleSet, rrulestr } from 'rrule';
import { addHour, addDate, addSeconds } from './dateUtil';

// /**
//  * 曜日情報を受け取って、それを元に毎週繰り返すRRULE文字列を返却する
//  *
//  * @param
//  * @returns
//  */
// export function getWeeklyRRule(weekday: number[], hh: number, mm: number) {
//   const rule = new RRule({
//     freq: RRule.WEEKLY,
//     byweekday: weekday,
//     dtstart: getBaseUTCDateTime(hh, mm),
//     until: new Date(Date.UTC(2100, 1, 1)),
//   });
//   return rule.toString();
// }

/**
 * RRULE文字列を返却する
 *
 * @param
 * @returns
 */
export function getRRule(weekday: number[], interval: number, startDate: Date, hh: number, mm: number) {
  const rule = new RRule({
    freq: RRule.WEEKLY,
    byweekday: weekday,
    dtstart: getBaseUTCDateAndTime(startDate, hh, mm),
    interval: interval,
    until: new Date(Date.UTC(2100, 1, 1)),
    wkst: RRule.SU, // Fullcalenderの定義と合わせて日曜始まりにする
  });
  return rule.toString();
}

// export function getBaseDate(): Date {
//   return new Date(Date.UTC(2020, 1, 1));
// }

export function getUTCDate(date: Date): Date {
  return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
}

export function getBaseUTCDateAndTime(date: Date, hh: number, mm: number): Date {
  return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), hh, mm));
}

export function getBaseUTCDateTime(hh: number, mm: number): Date {
  return new Date(Date.UTC(2020, 1, 1, hh, mm));
}
export function getBaseDateTime(hh: number, mm: number): Date {
  return new Date(2020, 1, 1, hh, mm);
}
// export function getStartDateTime(rrule: string, targetDate: Date): Date {
//   const options = RRule.parseString(rrule);
//   const ret = new Date(options.dtstart.getTime());
//   ret.setFullYear(targetDate.getFullYear());
//   ret.setMonth(targetDate.getMonth());
//   ret.setDate(targetDate.getDate());
//   ret.setHours(ret.getHours() - 9);
//   ret.setMinutes(ret.getMinutes());
//   return ret;
// }

export function addExdates(rrule: string, exdates: Date[], hh: number, mm: number): string {
  // JSTで渡す。内部でUTC
  if (!exdates) {
    return rrule;
  }
  const cloneExdate = exdates.map(ex => {
    return new Date(Date.UTC(ex.getFullYear(), ex.getMonth(), ex.getDate(), hh, mm));
  });
  const rruleSet = new RRuleSet();
  rruleSet.rrule(RRule.fromString(rrule));
  cloneExdate.forEach(d => rruleSet.exdate(d));
  return rruleSet.toString();
}

// export function setBaseTime(rrule: string, baseTime: Date): string {
//   const rule = RRule.parseString(rrule);
//   rule.dtstart = getBaseDateTime(baseTime.getHours(), baseTime.getMinutes());
//   return rule.toString();
// }

// export function getWeekDayFromRRule(rrule: string): [] {
//   const rule = RRule.parseString(rrule);
//   //@ts-ignore
//   const ret = rule.byweekday.map(w => WeekDay.getByCode(w.weekday));
//   return ret;
// }

/**
 * @param 月、火とか
 * @returns The arithmetic mean of `x` and `y`
 */
export function getBetweenDates(rruleStr: string, start: Date, end: Date) {
  const rule = rrulestr(rruleStr);
  const utcStartDate = addSeconds(getUTCDate(start), -1); //0時ぴったのケアプランが引っかからないため
  const utcEndDate = addDate(getUTCDate(end), 1);
  // RRULEはUTC基準で時刻設定されているので、そのぶんを減算する
  const ret = rule.between(utcStartDate, utcEndDate).map(v => addHour(v, -9));
  return ret;
}

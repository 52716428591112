import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IEventModel, IEventModelHash } from './model';

export type EventState = {
  events: IEventModel[];
  eventHash: IEventModelHash;
};

const initialState: EventState = {
  events: [],
  eventHash: {},
};

export const eventModule = createSlice({
  name: 'event',
  initialState,
  reducers: {
    clear(state: EventState, action: PayloadAction<void>) {
      state.eventHash = {};
      state.events = [];
    },
    get(state: EventState, action: PayloadAction<IEventModel[]>) {
      state.eventHash = action.payload.reduce((a, v) => {
        a[v.id] = v;
        return a;
      }, {});
      state.events = [...action.payload];
    },
    create(state: EventState, action: PayloadAction<IEventModel>) {
      const newStateHash = { ...state.eventHash };
      newStateHash[action.payload.id] = action.payload;
      state.eventHash = newStateHash;
      state.events = [...state.events, action.payload];
    },
    bulkCreate(state: EventState, action: PayloadAction<IEventModel[]>) {
      const newStateHash = { ...state.eventHash };
      action.payload.forEach(v => {
        newStateHash[v.id] = v;
      });
      state.eventHash = newStateHash;
      state.events = [...state.events, ...action.payload];
    },
    update(state: EventState, action: PayloadAction<IEventModel>) {
      const newStateHash = { ...state.eventHash };
      newStateHash[action.payload.id] = action.payload;
      state.eventHash = newStateHash;
      state.events = [...state.events.filter(v => v.id !== action.payload.id), action.payload];
    },
    bulkUpdate(state: EventState, action: PayloadAction<IEventModel[]>) {
      const newStateHash = { ...state.eventHash };
      const newEventIds = action.payload.map(v => v.id);
      action.payload.forEach(v => {
        newStateHash[v.id] = v;
      });
      state.eventHash = newStateHash;
      state.events = [...state.events.filter(v => !newEventIds.includes(v.id)), ...action.payload];
    },
    // test(state: EventState, action: PayloadAction<void>) {
    // },
    remove(state: EventState, action: PayloadAction<string>) {
      const newStateHash = { ...state.eventHash };
      delete newStateHash[action.payload];
      state.eventHash = newStateHash;
      state.events = [...state.events.filter(v => v.id !== action.payload)];
    },
  },
});

export const { get, create, update, remove } = eventModule.actions;

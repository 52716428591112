import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import 'react-perfect-scrollbar/dist/css/styles.css';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import store from './__share__/modules/store';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { rrfProps } from './__share__/config/fbConfig';

ReactDOM.render(
  <Provider store={store}>
    <ReactReduxFirebaseProvider {...rrfProps}>
      <App />
    </ReactReduxFirebaseProvider>
  </Provider>,
  document.getElementById('root')
);
serviceWorker.unregister();

import { useDispatch } from 'react-redux';
import { useSelector } from '../../__share__/modules/reducer';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import React from 'react';
import { clearMessage } from '../../__share__/modules/view';
import { makeStyles, Box, CircularProgress } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    left: 0,
    padding: theme.spacing(3),
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: 2000,
  },
}));

function ViewStateController() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const view = useSelector(state => state.view);
  const dispatch = useDispatch();

  useEffect(() => {
    const msg = view.message;
    if (msg) {
      enqueueSnackbar(msg.message, {
        variant: msg.level,
      });
      dispatch(clearMessage());
    }
  }, [dispatch, enqueueSnackbar, view]);

  return (
    <>
      {view.isLoading && (
        <div className={classes.root}>
          <Box display="flex" justifyContent="center" mb={6}>
            <CircularProgress color="secondary" disableShrink />
          </Box>
        </div>
      )}
    </>
  );
}
export default React.memo(ViewStateController);

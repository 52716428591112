import { BaseConstants } from './_BaseConstants';

export class AccountRole extends BaseConstants {
  static readonly CURAE_ADMIN = new AccountRole(
    'CURAE_ADMIN',
    'クラエ管理者',
    'サポートが利用するアカウントです'
  );
  static readonly ADMIN = new AccountRole(
    'ADMIN',
    '管理者',
    'データの閲覧と更新、削除が可能な権限です。本サイトを操作するサービス提供責任者に割当ててください。'
  );
  static readonly GENERAL = new AccountRole(
    'GENERAL',
    '一般',
    'データの閲覧と最低限の更新のみ可能な権限です。スマートフォンアプリを利用するヘルパーに割当ててください。'
  );

  private constructor(
    public readonly key: string,
    public readonly label: string,
    public readonly description: string
  ) {
    super();
  }

  toString() {
    return this.key;
  }
}

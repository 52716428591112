import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from '@reduxjs/toolkit';
import { ErrorState, catchError, clearError } from '../../__share__/modules/error';
import { RootState } from '../../__share__/modules/reducer';
import Error503View from './Error503View';
import { clear } from '../../__share__/modules/view';

type Props = IDispatchToProps &
  IStateToProps & {
    children: any;
  };
class ErrorBoundary extends React.Component<Props> {
  componentDidUpdate = () => {
    if (this.props.error) {
      this.props.clearLoading();
      console.log(this.props.error);
      // エラー画面に遷移
    }
  };

  // Reactのライフサイクル時エラーの場合はこちらが動作する
  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    if (error) {
      const errorLog = {
        path: window.location.href,
        error: `${error}`,
        // id: this.context.currentUser.userId,
      };
      console.log('catch');
      console.log(errorLog);
      this.props.catchError(error);
      // コンポーネントエラーの場合はエラー画面を表示させる
      // this.props.history.push(`/error?msg=${JSON.stringify(errorLog)}`);
    }
  }

  componentDidMount() {}

  render() {
    // return <>{this.props.error.hasError ? this.props.error.error.message : this.props.children}</>;
    return <>{this.props.error.hasError ? <Error503View /> : this.props.children}</>;

    // return <>{this.props.children}</>;
  }
}

export interface IDispatchToProps {
  catchError: Function;
  clearError: Function;
  clearLoading: Function;
}

export interface IStateToProps {
  error?: ErrorState;
}

const mapStateToProps = (state: RootState): IStateToProps => {
  return {
    error: state.error,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    catchError: (error: Error) => {
      dispatch(clear());
      dispatch(catchError(error));
    },
    clearError: (error: Error) => {
      dispatch(clear());
      dispatch(clearError());
    },
    clearLoading: () => {
      dispatch(clear());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorBoundary);

import { RuntimeError } from '../error/errors';
import { hiraToKana, removeSpace } from '../../utils/stringUtil';
import BaseModel from './_BaseModel';

export const toHash = (models: any[]) => {
  if (!models) {
    return;
  }
  return models.reduce((a, v) => {
    a[v.id] = v;
    return a;
  }, {});
};
export const byId = <T extends BaseModel>(models: T[], id: string): any => {
  const ret = models.find(model => model.id === id);
  if (ret) {
    return ret;
  }
  throw new RuntimeError('invalid state');
};

export const distinct = (models: any[], key: string = 'id') => {
  return models.filter((v1, i1, s) => s.findIndex(v2 => v1[key] === v2[key]) === i1);
};

export const byName = (models: any[], name: string) => {
  if (!models) {
    return;
  }
  const ret = models.find(model => model.name === name);
  if (ret) {
    return ret;
  } else {
    return;
  }
};
export const remove = (models: any[], id: string) => {
  if (!models) {
    return [];
  }
  return models.filter(model => model.id !== id);
};

export const applyFilters = (models: any[], query: string, properties: string[]) => {
  return models.filter(model => {
    let matches = true;
    // カタカタのフィールドが存在する前提で、クエリ内の平仮名はカタカタに変換する
    const targetQuery = hiraToKana(removeSpace(query));
    if (targetQuery) {
      let containsQuery = false;
      properties.forEach(property => {
        if (removeSpace(model[property]).includes(targetQuery)) {
          containsQuery = true;
        }
      });
      if (!containsQuery) {
        matches = false;
      }
    }
    return matches;
  });
};

export function applySort(models: any, orderBy: string, order: string) {
  const comparator = getComparator(order, orderBy);
  const stabilizedThis = models.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    // eslint-disable-next-line no-shadow
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

export function applyPagination(models: any, page: number, limit: number) {
  return models.slice(page * limit, page * limit + limit);
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

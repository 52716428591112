import firebase from 'firebase/app';
import { firebaseConfig } from '../../config/fbConfig';

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

export default class AppDataHolder {
  static myInstance = new AppDataHolder();

  _accountId = '';
  _organizationId = '';
  _workerId = '';

  /**
   * @returns {AppDataHolder}
   */
  static getInstance(): AppDataHolder {
    if (AppDataHolder.myInstance == null) {
      AppDataHolder.myInstance = new AppDataHolder();
    }
    return this.myInstance;
  }
  public clear() {
    this._accountId = '';
    this._organizationId = '';
    this._workerId = '';
  }
  public getDbPath() {
    return `/organizations/${this._organizationId}`;
  }
  public getAccountId() {
    return this._accountId;
  }
  public setAccountId(val) {
    this._accountId = val;
  }
  public getWorkerId() {
    return this._workerId;
  }
  public setWorkerId(val) {
    this._workerId = val;
  }
  public getOrganizationId() {
    return this._organizationId;
  }
  public setOrganizationId(val) {
    this._organizationId = val;
  }
  public isLoaded() {
    if (!this._organizationId || !this._accountId) return false;
    return true;
  }
}

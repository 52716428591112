import { Theme } from '@material-ui/core';
import { deepPurple, deepOrange, grey } from '@material-ui/core/colors';

export const gray = (theme: Theme, size: number = 42, hasMarginRight = true) => {
  return {
    height: size,
    width: size,
    marginRight: hasMarginRight ? theme.spacing(1) : theme.spacing(0),
    color: theme.palette.getContrastText(grey[200]),
    backgroundColor: grey[200],
  };
};

export const purple = (theme: Theme, isHover = true, size: number = 42, hasMarginRight = true) => {
  const baseOpt = {
    height: size,
    width: size,
    marginRight: hasMarginRight ? theme.spacing(1) : theme.spacing(0),
    color: theme.palette.getContrastText(deepPurple[400]),
    backgroundColor: deepPurple[300],
  };
  const hoverOpt = {
    '&:hover': {
      background: deepPurple[800],
    },
  };
  return isHover
    ? {
        ...baseOpt,
        ...hoverOpt,
      }
    : baseOpt;
};

export const orange = (theme: Theme, isHover = true, size: number = 42, hasMarginRight = true) => {
  const baseOpt = {
    height: size,
    width: size,
    marginRight: hasMarginRight ? theme.spacing(1) : theme.spacing(0),
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[300],
  };
  const hoverOpt = {
    '&:hover': {
      background: deepOrange[800],
      cursor: 'pointer',
    },
  };
  return isHover
    ? {
        ...baseOpt,
        ...hoverOpt,
      }
    : baseOpt;
};

import { AccountRole } from '../../constants/enums/accountRole';
import { License } from '../../constants/enums/license';
import BaseModel, { IBaseModel } from '../common/_BaseModel';

export type IAccountModelHash = { [key: string]: IAccountModel };
export type IAccountModel = IBaseModel & {
  workerId: string;
  organizationId: string;
  email: string;
  role: string;
  license: string;
  isValid: boolean;
  deviceToken: string;
  isTriggerAccount: boolean; // firebaseID作成時に一時的に作成するアカウントの場合にのみtrue(通常はすべてfalse)
};
export class AccountModel extends BaseModel implements IAccountModel {
  workerId: string = '';
  organizationId: string = '';
  email: string = '';
  role: string = AccountRole.ADMIN.key; // TODO：firestore ruleで使っている
  license: string = License.FREE.key;
  isValid: boolean = true;
  deviceToken: string = '';
  isTriggerAccount: boolean = false;

  constructor(param?: Partial<IAccountModel>) {
    super();
    Object.assign(this, param);
  }
}

import { AsYouType } from 'libphonenumber-js';
import { formatFull } from './dateUtil';

export const kanaRegExp = /^[ァ-ンヴー]+$/;
export const hiraRegExp = /^[ぁ-んー]+$/;
export const numberRegExp = /^[0-9]+$/;
export const hanRegExp = /^[a-zA-Z]+$/;
export const urlRegExp = /^https?:/;
export const emailRegExp =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export function kanaToHira(text: string) {
  return text.replace(/[\u30a1-\u30f6]/g, function (match) {
    var chr = match.charCodeAt(0) - 0x60;
    return String.fromCharCode(chr);
  });
}
export function removeSpace(text: string) {
  return text.replace(/\s+/g, '');
}
export function removeAll(text: string, keyword: string) {
  return text.split(keyword).join('');
}

export function hiraToKana(str) {
  return str.replace(/[\u3041-\u3096]/g, function (match) {
    var chr = match.charCodeAt(0) + 0x60;
    return String.fromCharCode(chr);
  });
}

export function getInitials(name = '') {
  return name
    .replace(/\s+/, ' ')
    .split(' ')
    .slice(0, 2)
    .map(v => v && v[0].toUpperCase())
    .join('');
}

export function getFileName(fileName: string) {
  return fileName.replace(/\.[^/.]+$/, '');
}

export function zeroPadding(num: number | string, length: number): string {
  return ('0000000000' + num).slice(-length);
}

export function replaceParam(org: string, param: string, value: string) {
  return org.replace(`:${param}`, value);
}
export function formatPhoneNo(target: string) {
  return new AsYouType('JP').input(target);
}

export function toJsonWithZonedDate(obj) {
  return JSON.stringify(obj, function (key, value) {
    if (this[key] instanceof Date) {
      return formatFull(this[key]);
    }
    return value;
  });
}

export async function sha256(text) {
  const uint8 = new TextEncoder().encode(text);
  const digest = await crypto.subtle.digest('SHA-256', uint8);
  return Array.from(new Uint8Array(digest))
    .map(v => v.toString(16).padStart(2, '0'))
    .join('');
}

export async function countTableSize(htmlText) {
  return (htmlText.match(/<tr>/g) || []).length;
}

export async function countBrSize(htmlText) {
  return (htmlText.match(/<br/g) || []).length;
}

export async function countHtmlSize(htmlText) {
  return countBrSize(htmlText) + countHtmlSize(htmlText);
}
export function generateUuid() {
  // https://github.com/GoogleChrome/chrome-platform-analytics/blob/master/src/internal/identifier.js
  // const FORMAT: string = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx";
  let chars = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.split('');
  for (let i = 0, len = chars.length; i < len; i++) {
    switch (chars[i]) {
      case 'x':
        chars[i] = Math.floor(Math.random() * 16).toString(16);
        break;
      case 'y':
        chars[i] = (Math.floor(Math.random() * 4) + 8).toString(16);
        break;
    }
  }
  return chars.join('');
}

export function getSearchCount(text: string, keyword: string) {
  return (text.match(new RegExp(keyword, 'g')) || []).length;
}

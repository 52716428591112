// 全権限のリスト
export const AUTH = {
  dashboard: {
    view: 'dashboard:view',
  },
  navbar: {
    list: 'navbar:list',
  },
  worker: {
    edit: 'worker:edit',
  },
  patient: {
    edit: 'patient:edit',
    viewCompatibility: 'patient:viewCompatibility',
  },
  careplan: {
    edit: 'careplan:edit',
  },
  careservice: {
    create: 'careservice:create',
    edit: 'careservice:edit',
  },
  chat: {
    showTel: 'chat:showTel',
  },
  report: {
    list: 'report:list',
    editOwn: 'report:editOwn',
    edit: 'report:edit',
    editAssign: 'report:editAssign',
    editCaution: 'report:editCaution',
  },
  account: {
    view: 'account:view',
    create: 'account:create',
    edit: 'account:edit',
  },
  event: {
    list: 'event:list',
    edit: 'event:edit',
    assign: 'event:assign',
  },
  setting: {
    view: 'setting:view',
    edit: 'setting:edit',
    initData: 'setting:initData',
  },
  task: {
    submit: 'task:submit',
    submitAll: 'task:submitAll',
  },
};

export const PART_HELPER_NAV = [
  '/app/patients/schedule',
  '/app/workers/schedule',
  '/app/chat',
  '/app/navigation',
  '/app/workers/salary',
  '/app/patients',
  '/app/workers',
];

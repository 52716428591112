import moment from 'moment';

const guardNumber = inp => (typeof inp === 'string' ? parseInt(inp) : inp);

export function addMonth(target: Date, additional: number) {
  return moment(target).add(guardNumber(additional), 'months').toDate();
}

export function addDate(target: Date, additional: number) {
  return moment(target).add(guardNumber(additional), 'days').toDate();
}

export function addHour(target: Date, additional: number) {
  return moment(target).add(guardNumber(additional), 'hours').toDate();
}

export function addMinutes(target: Date, additional: number) {
  return moment(target).add(guardNumber(additional), 'minutes').toDate();
}

export function addSeconds(target: Date, additional: number) {
  return moment(target).add(guardNumber(additional), 'seconds').toDate();
}

export function convertToHHMM(minutes: number) {
  const hours = guardNumber(minutes) / 60;
  const rhours = Math.floor(hours);
  const rminutes = Math.round((hours - rhours) * 60);
  return ('000' + rhours).slice(-2) + ':' + ('000' + rminutes).slice(-2);
}
export function convertToHM(minutes: number) {
  const hours = guardNumber(minutes) / 60;
  const rhours = Math.floor(hours);
  const rminutes = Math.round((hours - rhours) * 60);
  return rhours + ':' + rminutes;
}
export function convertToHMM(minutes: number) {
  const hours = guardNumber(minutes) / 60;
  const rhours = Math.floor(hours);
  const rminutes = Math.round((hours - rhours) * 60);
  return rhours + ':' + ('000' + rminutes).slice(-2);
}
export function convertToHHMMLabel(minutes: number) {
  const hours = guardNumber(minutes) / 60;
  const rhours = Math.floor(hours);
  const rminutes = Math.round((hours - rhours) * 60);
  return rhours + '時間' + rminutes + '分';
}
export function setYYYMMDD(baseDate: Date, yyyymmdd: string): Date {
  if (!/^(\d){8}$/.test(yyyymmdd)) return null;
  const yyyy = yyyymmdd.substr(0, 4),
    mm = yyyymmdd.substr(4, 2),
    dd = yyyymmdd.substr(6, 2);
  const result = new Date(baseDate.getTime());
  result.setFullYear(parseInt(yyyy));
  result.setMonth(parseInt(mm) - 1);
  result.setDate(parseInt(dd));
  return result;
}

// 10分間隔で切り捨て
export function roundMinute(date: Date, interval: number): Date {
  const start = moment(date);
  const remainder = (start.minute() % interval === 0 ? 0 : interval - start.minute()) % interval;
  return moment(start).add(remainder, 'minutes').toDate();
}

export function setHHMM(baseDate: Date, hh: number, mm: number): Date {
  const result = new Date(baseDate.getTime());
  result.setHours(hh);
  result.setMinutes(mm);
  return result;
}
export function getSunday(target: Date) {
  const result = new Date(target.getTime());
  result.setDate(result.getDate() - target.getDay());
  return result;
}
export function getYesterday(): Date {
  return startDayOf(addDate(moment().toDate(), -1));
}
export function getJapaneseYear(target: Date) {
  return moment(target).add(guardNumber(-3), 'months');
}
export function toJST(target: Date): Date {
  const nextDate = new Date();
  nextDate.setTime(target.getTime() + 1000 * 60 * 60 * 9);
  return nextDate;
}
export function startJapaneseYearOf(target: Date) {
  return moment(target).add(guardNumber(-3), 'months').startOf('year').add(guardNumber(3), 'months').toDate();
}
export function startYearOf(target: Date) {
  return moment(target).startOf('year').toDate();
}
export function startMonthOf(target: Date) {
  return moment(target).startOf('month').toDate();
}
export function endMonthOf(target: Date) {
  return moment(target).endOf('month').toDate();
}
export function endYearOf(target: Date) {
  return moment(target).endOf('year').toDate();
}
export function endJapaneseYearOf(target: Date) {
  return moment(target).add(guardNumber(-3), 'months').endOf('year').add(guardNumber(3), 'months').toDate();
}
export function startWeekOf(target: Date) {
  return moment(target).startOf('week').toDate();
}
export function endWeekOf(target: Date) {
  return moment(target).endOf('week').toDate();
}
export function startDayOf(target: Date): Date {
  return moment(target).startOf('day').toDate();
}
export function endDayOf(target: Date): Date {
  return moment(target).endOf('day').toDate();
}
export function startDayOfString(target: Date): string {
  return moment(target).startOf('day').format('YYYY-MM-DDTHH:mm:ss');
}
export function endDayOfString(target: Date): string {
  return moment(target).endOf('day').format('YYYY-MM-DDTHH:mm:ss');
}
export function startHourOf(target: Date): Date {
  return moment(target).minute(0).seconds(0).toDate();
}
export function endHourOf(target: Date): Date {
  return moment(target).minute(59).seconds(59).toDate();
}
export function isBeforeFromToday(target: Date, days: number): boolean {
  return moment().diff(target, 'days') >= days;
}
export function isPastDate(target: Date): boolean {
  return moment().isAfter(target);
}
export function isSameDate(base: Date, target: Date): boolean {
  return moment(base).isSame(target, 'day');
}
export function isAfter(base: Date, target: Date): boolean {
  return moment(base).isAfter(target);
}
export function isBefore(base: Date, target: Date): boolean {
  return moment(base).isBefore(target);
}
export function getDateOrToday(target: Date): Date {
  return moment().isBefore(target) ? startDayOf(target) : startDayOf(moment().toDate());
}
export function isFutureDate(target: Date): boolean {
  return moment().isBefore(target);
}
//明日以降の日付
export function isAfterTomorrowDate(target: Date): boolean {
  return moment().endOf('day').isBefore(target);
}
export function diffMinutes(from: Date, to: Date): number {
  const diff = moment(to).diff(moment(from), 'minutes');
  return diff > 0 ? diff : -diff;
}
export function getDateFromYYYYMM(target: string): Date {
  return moment(target, 'YYYYMM').toDate();
}
export function getDateFromYYYYMMDD(target: string): Date {
  return moment(target, 'YYYYMMDD').toDate();
}
export function getDateFromYYYYMMDDHHmm(target: string): Date {
  return moment(target, 'YYYYMMDDHHmm').toDate();
}
export function getDateFromYYYYMMDDHyphen(target: string): Date {
  return moment(target, 'YYYY-MM-DD').toDate();
}
export function getDateFromhhmm(target: string): Date {
  return moment(target, 'HH:mm').toDate();
}
export function formatddd(target: Date): string {
  return moment(target).format('ddd');
}
export function formatYYYYMM(target: Date): string {
  return moment(target).format('YYYYMM');
}
export function formatYYYYMMSlash(target: Date): string {
  return moment(target).format('YYYY/MM');
}
export function formatJapaneseYear(target: Date): string {
  return moment(target).add(guardNumber(-3), 'months').format('YYYY');
}
export function formatYYYYMMJapaneseDate(target: Date): string {
  // return moment(target).format('YYYY-MM-DDTHH:mm:ss');
  return moment(target).format('YYYY年M月');
}
export function formatFullcalenderDate(target: Date): string {
  // return moment(target).format('YYYY-MM-DDTHH:mm:ss');
  return moment(target).format('YYYY-MM-DD');
}
export function formatFullJapaneseDate(target: Date): string {
  // return moment(target).format('YYYY-MM-DDTHH:mm:ss');
  return moment(target).format('YYYY年M月D日');
}
export function formatYYYYMMDD(target: Date): string {
  return moment(target).format('YYYYMMDD');
}
export function format(target: Date): string {
  return moment(target).format();
}
export function formatFull(target: Date): string {
  return moment(target).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
}
export function formatMMDDhhmm(target: Date): string {
  return moment(target).format('MM/DD HH:mm');
}
export function formatMDAhhmm(target: Date): string {
  return moment(target).format('M月D日(ddd) HH:mm');
}
export function formathhmm(target: Date): string {
  return moment(target).format('HH:mm');
}
export function formatYYYYMMDDSlash(target: Date): string {
  return moment(target).format('YYYY/MM/DD');
}
export function formatMMDDSlash(target: Date): string {
  return moment(target).format('MM/DD');
}
export function formatMDSlash(target: Date): string {
  return moment(target).format('M/D');
}
export function formatMDALabel(target: Date): string {
  return moment(target).format('M月D日(ddd)');
}
export function formatMDASlashLabel(target: Date): string {
  return moment(target).format('M/D(ddd)');
}
export function formatDALabel(target: Date): string {
  return moment(target).format('D(ddd)');
}
export function formatD(target: Date): string {
  return moment(target).format('D');
}
export function formatM(target: Date): string {
  return moment(target).format('M');
}
export function fromNow(target: Date): string {
  //チャット履歴が数秒後になるので20秒減算
  return moment(target).add(-20, 'second').fromNow();
}

export function getRangeDate(start: Date, end: Date) {
  const dateArray = [];
  let currentDate = moment(start);
  while (currentDate <= moment(end)) {
    dateArray.push(moment(currentDate).toDate());
    currentDate = moment(currentDate).add(1, 'days');
  }
  return dateArray;
}

export function getRangeFormatDate(format: string = 'YYYYMMDD', start: Date, end: Date): string[] {
  const dateArray = [];
  let currentDate = moment(start);
  while (currentDate <= moment(end)) {
    dateArray.push(moment(currentDate).format(format));
    currentDate = moment(currentDate).add(1, 'days');
  }
  return dateArray;
}

export function getRangeYYYYMM(start: Date, end: Date): string[] {
  const dateArray = [];
  let currentDate = moment(start);
  while (currentDate <= moment(end)) {
    dateArray.push(moment(currentDate).format('YYYYMM'));
    currentDate = moment(currentDate).add(1, 'month');
  }
  return dateArray;
}
export function getRangeYYYYMMDD(start: Date, end: Date): string[] {
  const dateArray = [];
  let currentDate = moment(start);
  while (currentDate <= moment(end)) {
    dateArray.push(moment(currentDate).format('YYYYMMDD'));
    currentDate = moment(currentDate).add(1, 'days');
  }
  return dateArray;
}

export function getRangeYYYYMMDDInFuture(start: Date, end: Date): string[] {
  const dateArray = [];
  const today = moment();
  let currentDate = moment(start).isBefore(today) ? today : moment(start);
  while (currentDate <= moment(end)) {
    dateArray.push(moment(currentDate).format('YYYYMMDD'));
    currentDate = moment(currentDate).add(1, 'days');
  }
  return dateArray;
}

export function getRangeddd(start: Date, end: Date) {
  const dateArray = [];
  let currentDate = moment(start);
  while (currentDate <= moment(end)) {
    dateArray.push(moment(currentDate).toDate().getDay());
    currentDate = moment(currentDate).add(1, 'days');
  }
  return dateArray;
}

export function isOverlapped(startDate: Date, endDate: Date, otherStartDate: Date, otherEndDate: Date) {
  // 比較開始日付 <= 対象終了日付 AND 比較終了日付 >= 対象開始日付
  return startDate.getTime() <= otherEndDate.getTime() && endDate.getTime() >= otherStartDate.getTime();
}

export function isOverlappedHHmm(
  startHHmm: string,
  endHHmm: string,
  otherStartHHmm: string,
  otherEndHHmm: string
) {
  // 比較開始日付 <= 対象終了日付 AND 比較終了日付 >= 対象開始日付
  return isOverlapped(
    getDateFromhhmm(startHHmm),
    getDateFromhhmm(endHHmm),
    getDateFromhhmm(otherStartHHmm),
    getDateFromhhmm(otherEndHHmm)
  );
}

// export function getDateByUtc(targetDate) {
//   let yyyy = moment(targetDate).format('YYYY');
//   let mm = moment(targetDate).format('MM');
//   let dd = moment(targetDate).format('DD');
//   return new Date(Date.UTC(yyyy, mm, dd));
// }

// export function getUtcDateByStr(dateStr) {
//   let [yyyy, mm, dd] = dateStr.split('/');
//   return new Date(Date.UTC(yyyy, mm, dd));
// }

// export function getDateByStr(dateStr) {
//     let [yyyy,mm,dd] = dateStr.split('/');
//     return new Date(Date.UTC(yyyy,mm,dd))
// }

import { BaseConstants } from './_BaseConstants';

export class License extends BaseConstants {
  static readonly FREE = new License('FREE', '無料ユーザ', 30);
  static readonly ENTERPRISE = new License('ENTERPRISE', '有料ユーザ', 180);

  private constructor(
    public readonly key: string,
    public readonly label: string,
    public readonly limitBefore: number
  ) {
    super();
  }

  toString() {
    return this.key;
  }
}

import React from 'react';
import { Typography } from '@material-ui/core';

export default function Typo({ value, variant = 'body2' as any, color = 'textSecondary' as any, ...rest }) {
  return (
    <Typography variant={variant} color={color} {...rest}>
      {value}
    </Typography>
  );
}

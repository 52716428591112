import React from 'react';
import { useLocation, matchPath } from 'react-router';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Box, Drawer, List, ListSubheader, makeStyles } from '@material-ui/core';
import NavItem from './NavItem';
import { navConfig } from '../../../../constants/navigation';
import clsx from 'clsx';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { CustomTheme } from '../../../../theme';
import Can from '../../../app/Can';
import { AUTH } from '../../../../__share__/constants/auth';
import { useProfile } from '../../../app/AppContext';
import Typo from '../../../components/site/Typo';
import { CURAE_GUIDE_URL } from '../../../../__share__/constants/constants';

type RenderNavItemsProps = {
  items?: any;
  rest?: any[];
  open: boolean;
  functionNotifications: any;
  profile: any;
};
function renderNavItems(props: RenderNavItemsProps & ReduceChildRoutesProps) {
  const { items, open, functionNotifications, profile, ...rest } = props;
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, open, functionNotifications, profile, ...rest }),
        []
      )}
    </List>
  );
}

type ReduceChildRoutesProps = {
  acc?: any;
  pathname?: string;
  item?: any;
  depth?: number;
  open: boolean;
  functionNotifications: any;
  profile: any;
};
function reduceChildRoutes(props: ReduceChildRoutesProps) {
  const { acc, pathname, item, open, functionNotifications, profile, depth = 0 } = props;
  const key = item.title + depth;
  let linkOpen = item.match.reduce(
    (ret, lnk) => ret || Boolean(matchPath(pathname, { path: lnk, exact: true })),
    false
  );

  if (item.items) {
    acc.push(
      <Can
        role={profile.worker.getRole()}
        perform={AUTH.navbar.list}
        key={key}
        data={{
          path: item.href,
        }}
      >
        <NavItem
          depth={depth}
          item={item}
          icon={item.icon}
          info={item.info}
          title={item.title}
          iconTitle={item.iconTitle}
          open={open}
          linkOpen={Boolean(linkOpen)}
          functionNotifications={functionNotifications}
        >
          {renderNavItems({
            depth: depth + 1,
            pathname,
            open,
            items: item.items,
            functionNotifications: functionNotifications,
            profile,
          })}
        </NavItem>
      </Can>
    );
  } else {
    acc.push(
      <Can
        role={profile.worker.getRole()}
        perform={AUTH.navbar.list}
        key={key}
        data={{
          path: item.href,
        }}
      >
        <NavItem
          depth={depth}
          item={item}
          href={item.href}
          icon={item.icon}
          linkOpen={Boolean(linkOpen)}
          open={open}
          info={item.info}
          title={item.title}
          iconTitle={item.iconTitle}
          functionNotifications={functionNotifications}
        />
      </Can>
    );
  }
  return acc;
}

const drawerWidth = 240;
const useStyles = makeStyles((theme: CustomTheme) => ({
  root: {
    backgroundColor: theme.palette.background.deep,
    color: theme.palette.common.white,
  },
  subheader: {
    color: theme.palette.common.white,
  },
  drawer: {
    zIndex: 500,
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    borderRight: 0,
  },
  listHeader: {
    lineHeight: '30px',
    height: '30px',
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    borderRight: 0,
    width: theme.spacing(7) + 1,
  },
  toggleArea: {
    width: '100%',
    cursor: 'pointer',
    backgroundColor: theme.palette.background.gray.light,
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: '#7F888E',
      fontWeight: 'bold',
    },
  },
}));

type NavBarProps = {
  open: boolean;
  toggleDrawerOpen: any;
  functionNotifications?: any;
};
function NavBar(props: NavBarProps) {
  const { toggleDrawerOpen, open, functionNotifications } = props;
  const profile = useProfile();
  const classes = useStyles();
  const location = useLocation();

  const content = (
    <Box className={classes.root} height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box style={{ paddingTop: 64 }}>
          <Box
            display="flex"
            className={classes.toggleArea}
            onClick={toggleDrawerOpen}
            justifyContent={open ? 'flex-end' : 'center'}
            alignItems="center"
          >
            {open ? <ChevronLeftIcon color="primary" /> : <ChevronRightIcon color="primary" />}
          </Box>
          <Box>
            {navConfig.map(config => (
              <Box key={`${config.subheader}_${config.items}`}>
                {open ? (
                  <Box px={2} pb={1}>
                    <List
                      subheader={
                        <ListSubheader
                          className={classes.listHeader}
                          color="inherit"
                          disableGutters
                          disableSticky
                        >
                          {config.subheader}
                        </ListSubheader>
                      }
                    >
                      {renderNavItems({
                        items: config.items,
                        pathname: location.pathname,
                        open,
                        functionNotifications,
                        profile,
                      })}
                    </List>
                  </Box>
                ) : (
                  <Box display="flex" justifyContent="center">
                    {renderNavItems({
                      items: config.items,
                      pathname: location.pathname,
                      open,
                      functionNotifications,
                      profile,
                    })}
                  </Box>
                )}
              </Box>
            ))}
            {open && (
              <Box p={2}>
                <Box p={2} borderRadius="borderRadius" bgcolor="common.white">
                  <a
                    href={CURAE_GUIDE_URL}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: 'none' }}
                  >
                    <Typo value="ヘルプ" variant="h6" color="textPrimary" />
                  </a>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </PerfectScrollbar>
    </Box>
  );
  // useTraceUpdate(props);
  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >
      {content}
    </Drawer>
  );
}
export default React.memo(NavBar);

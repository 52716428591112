import { AccountModel, IAccountModel, IAccountModelHash } from './model';
import { AccountService } from './service';

export const accountModelSelector = (input: IAccountModel[]): AccountModel[] => {
  if (!input) return [];
  const accountService = new AccountService();
  return input.map(w => accountService.trans2Model(w.id, w));
};

export const accountModelWorkerIdSelector = (input: IAccountModelHash, workerId: string): AccountModel => {
  if (input && workerId) {
    const accountService = new AccountService();
    return accountService.trans2Model(
      workerId,
      Object.values(input).find(v => v.workerId === workerId)
    );
  }
  return null;
};

import React from 'react';
import { Helmet } from 'react-helmet';
import { useProfile } from '../../app/AppContext';

const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID;

function GoogleAnalytics() {
  const profile = useProfile();
  if (process.env.NODE_ENV === 'development') return null;
  return (
    <Helmet>
      <script async src={`https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`} />
      <script>
        {`
          window.dataLayer = window.dataLayer || [];

          function gtag() {
            dataLayer.push(arguments);
          }

          gtag('js', new Date());
          gtag('config', '${GA_MEASUREMENT_ID}');
          gtag('set', 'user_properties', {
            worker: '${profile.workerId}',
            organization: '${profile.organizationId}',
          });
        `}
      </script>
    </Helmet>
  );
}

export default React.memo(GoogleAnalytics);

import BaseModel, { IBaseModel } from '../common/_BaseModel';
import { isAfter, getDateFromhhmm, isBefore, formatFullcalenderDate, startDayOf } from '../../utils/dateUtil';
import { formatPhoneNo } from '../../utils/stringUtil';
import { Sex } from '../../constants/enums/enum';
import { WeekDay } from '../../constants/enums/weekdays';
import { EventModel } from '../events/model';
import { CareserviceModel } from '../careservices/model';
import { Role } from '../../constants/enums/role';

export type LocStatus = 'arrived' | 'left';
export type WorkingTime = { day: number; start: string; end: string }[];

export const defaultWorkingTime = [
  { day: WeekDay.MONDAY.code, start: '08:00', end: '18:00' },
  { day: WeekDay.TUESDAY.code, start: '08:00', end: '18:00' },
  { day: WeekDay.WEDNESDAY.code, start: '08:00', end: '18:00' },
  { day: WeekDay.THURSDAY.code, start: '08:00', end: '18:00' },
  { day: WeekDay.FRIDAY.code, start: '08:00', end: '18:00' },
];
export type WorkerModelHash = { [key: string]: WorkerModel };
export type IWorkerModelHash = { [key: string]: IWorkerModel };
export type IWorkerModel = IBaseModel & {
  firstName: string;
  firstNameKana: string;
  lastName: string;
  lastNameKana: string;
  sex: string;
  addressPrefecture: string;
  address1: string;
  address2: string;
  addressPoint: { latitude: number; longitude: number };
  tel: string;
  birthday: Date;
  skills: string[];
  isValid: boolean;
  transportation: string;
  workingTime: string;
  role: string;
  locStatus: { time: Date; patientId: string; status: LocStatus }[];
  availableDegreeOfCareRequired: number;
  impossibleCareservice: string[];
};
export class WorkerModel extends BaseModel implements IWorkerModel {
  firstName: string = '';
  firstNameKana: string = '';
  lastName: string = '';
  lastNameKana: string = '';
  sex: string = 'M';
  addressPrefecture: string = '';
  address1: string = '';
  address2: string = '';
  addressPoint: { latitude: number; longitude: number } = { latitude: 0, longitude: 0 };
  tel: string = '';
  birthday: Date = new Date();
  skills: string[] = [];
  isValid: boolean = true;
  transportation: string = 'BIKE';
  workingTime: string = JSON.stringify(defaultWorkingTime);
  role: string = 'HELPER';
  locStatus: { time: Date; patientId: string; status: LocStatus }[] = [];
  availableDegreeOfCareRequired: number = 5; // 以下
  impossibleCareservice: string[] = [];

  constructor(param?: Partial<IWorkerModel>) {
    super();
    Object.assign(this, param);
  }
  public static isValid(hash: WorkerModelHash, id: string): boolean {
    return id && hash[id] && hash[id].isValid;
  }
  public static getNames(
    hash: WorkerModelHash,
    ids: string[],
    emptyLabel: string = '未割当',
    emptyWorkerName: string = '削除済みユーザ',
    sep: string = ','
  ): string {
    const validIds = ids.filter(v => hash[v]);
    if (validIds.length === 0) return emptyLabel;
    return validIds.map(v => (hash[v] ? hash[v].getFullName() : emptyWorkerName)).join(sep);
  }

  public getFullName(): string {
    return `${this.lastName} ${this.firstName}`;
  }
  public getFullNameKana(): string {
    return `${this.lastNameKana} ${this.firstNameKana}`;
  }
  public getBirthDay(): string {
    return `${formatFullcalenderDate(this.birthday)}`;
  }
  public getAddress(): string {
    return `${this.address1} ${this.address2}`;
  }
  public getGeoPointCsv(): string {
    return `${this.addressPoint.latitude},${this.addressPoint.longitude}`;
  }
  public getGeoPointObj() {
    return {
      lat: this.addressPoint.latitude,
      lng: this.addressPoint.longitude,
    };
  }
  public getPossibleCareservice(careservices: CareserviceModel[]): CareserviceModel[] {
    return careservices.filter(v => v.isValid).filter(v => !this.impossibleCareservice.includes(v.id));
  }

  public isGeoPointEmpty() {
    return this.addressPoint.latitude === 0 && this.addressPoint.longitude === 0;
  }
  public hasRole(role: Role) {
    return Role.getByKey(this.role) === role;
  }
  public getRole(): Role {
    return Role.getByKey(this.role);
  }
  public toResource(showBusiness = true): any {
    const ret = {
      id: this.id,
      title: this.getFullName(),
      lastNameKana: this.lastNameKana,
    };
    if (showBusiness) {
      return Object.assign(ret, { businessHours: this.getBusinessHours() });
    }
    return ret;
  }

  public toCsvDataObj() {
    return {
      firstName: this.firstName,
      firstNameKana: this.firstNameKana,
      lastName: this.lastName,
      lastNameKana: this.lastNameKana,
      sex: this.sex,
      addressPrefecture: this.addressPrefecture,
      address1: this.address1,
      address2: this.address2,
      addressPoint: JSON.stringify([this.addressPoint.latitude, this.addressPoint.longitude]),
      tel: `'${this.tel}`, // 0が消えるため
      birthday: this.birthday,
      skills: JSON.stringify(this.skills),
      isValid: this.isValid,
      workingTime: this.workingTime,
      role: this.role,
    };
  }
  public static fromCsvData(param?: Partial<IWorkerModel>) {
    const point = JSON.parse(param.addressPoint as any);
    const newParam = Object.assign(param, {
      skills: JSON.parse(param.skills as any),
      tel: param.tel.slice(1),
      addressPoint: { latitude: point[0], longitude: point[1] },
    });
    return new WorkerModel(newParam);
  }
  public getBusinessHours() {
    return this.getWorkingTime().map(v => {
      return {
        startTime: v.start,
        endTime: v.end,
        daysOfWeek: [WeekDay.getByCode(v.day).fullCalenderCode],
      };
    });
  }

  public isInBusinessHours(now: Date): boolean {
    const workingTime = this.getWorkingTime();
    const targetDayWorkingTime = workingTime.filter(v => v.day === now.getDay());
    let isIn = false;
    targetDayWorkingTime.forEach(time => {
      const start = getDateFromhhmm(time.start);
      const end = getDateFromhhmm(time.end);
      isIn = isIn || (isAfter(now, start) && isBefore(now, end));
    });
    return isIn;
  }
  public getTelNo(): string {
    return formatPhoneNo(this.tel);
  }
  public getSexLabel(): string {
    return Sex[this.sex];
  }
  public getWorkingTime(): WorkingTime {
    return JSON.parse(this.workingTime);
  }
  public getWorkingTimeForOptimizeParam() {
    return this.getWorkingTime().map(v => {
      return {
        start: v.start,
        end: v.end,
        day: WeekDay.getByCode(v.day).javaCode,
      };
    });
  }

  public setWorkingTime(value) {
    this.workingTime = JSON.stringify(value);
  }
  public getLocStatus(baseTime: Date, eventSteps: EventModel[]): { eventId: string; status: LocStatus } {
    const start = startDayOf(baseTime);
    //当日のbaseTimeまでに開始予定のevent
    const targetEvent = eventSteps.filter(v => isAfter(baseTime, v.start));
    if (targetEvent && targetEvent.length > 0) {
      targetEvent.sort((a, b) => a.start.getTime() - b.start.getTime());
      // const patients = targetEvent.map(v => v.patientId);
      //開始済み予定のeventに関連するlocstatus
      const locStatus = this.locStatus
        .filter(v => isBefore(start, v.time))
        .reduce((a, v) => {
          if (!a[v.patientId]) {
            a[v.patientId] = v;
          } else if (a[v.patientId].status === 'arrived' && v.status === 'left') {
            a[v.patientId] = v;
          }
          return a;
        }, {});
      let ret;
      targetEvent.forEach(v => {
        if (locStatus[v.patientId]) ret = { eventId: v.id, status: locStatus[v.patientId].status };
      });
      return ret;
    } else {
      return { eventId: null, status: null };
    }
  }

  public addLocStatus(time: Date, patientId: string, status: LocStatus) {
    const start = startDayOf(time);
    const newLoc = this.locStatus.filter(v => isBefore(start, v.time));
    newLoc.push({ time, patientId, status });
    this.locStatus = newLoc;
  }
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type MessageType =
  | { message: string; level: 'success' | 'error' | 'warning' | 'info' | 'default' }
  | '';
export type ViewState = {
  isLoading: boolean;
  message: MessageType;
};

const initialState: ViewState = {
  isLoading: false,
  message: null,
};

export const viewModule = createSlice({
  name: 'view',
  initialState,
  reducers: {
    execute(state: ViewState, action: PayloadAction<void>) {
      state.isLoading = true;
    },
    complete(state: ViewState, action: PayloadAction<MessageType>) {
      state.isLoading = false;
      state.message = action.payload;
    },
    clearMessage(state: ViewState, action: PayloadAction<void>) {
      state.message = null;
    },
    clear(state: ViewState, action: PayloadAction<void>) {
      state.isLoading = false;
      state.message = null;
    },
  },
});

export const { execute, complete, clearMessage, clear } = viewModule.actions;

import { check } from '../../__share__/utils/authCheckUtil';
import React from 'react';
import { Role } from '../../__share__/constants/enums/role';

interface Props {
  role: Role;
  perform: string;
  data?: Object;
  yes?: Function;
  no?: Function;
  children?: any;
}
function Can(props: Props) {
  const { children, role, perform, data, yes, no = () => null } = props;
  if (!check(role, perform, data)) return no();
  if (yes) return yes();
  return children;
}

export default React.memo(Can);

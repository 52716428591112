import { actionTypes } from 'redux-firestore';
import { careplanModule } from '../careplans';
import AppDataHolder from '../common/appDataHolder';
import { eventModule } from '../events';
import { viewModule } from '../view';
import authService from './service';

export function login(email, password) {
  return async dispatch => {
    try {
      await authService.loginWithEmailAndPassword(email, password).catch(err => {
        throw new Error('throw from await/catch');
      });
    } catch (error) {
      throw error;
    }
  };
}

export function logout() {
  return async dispatch => {
    await dispatch({ type: actionTypes.CLEAR_DATA });
    await dispatch(careplanModule.actions.clear());
    await dispatch(eventModule.actions.clear());
    await dispatch(viewModule.actions.clear());
    AppDataHolder.getInstance().clear();
    authService.logout();
  };
}

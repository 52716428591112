import { configureStore, getDefaultMiddleware, ThunkAction, Action } from '@reduxjs/toolkit';
import rootReducer, { RootState } from './reducer';

// const logger = store => next => action => {
//   console.log('fire action', action);
//   next(action);
// };

const middleware = [
  ...getDefaultMiddleware({
    serializableCheck: false, //react-redux-firestoreがシリアル化不可なオブジェクトをstateに格納するため・・
    immutableCheck: false, // 遅い警告がログに出て邪魔なので・・
  }),
  // logger,
];

const store = configureStore({
  reducer: rootReducer,
  middleware: middleware,
  devTools: process.env.NODE_ENV !== 'production',
});

export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;
export default store;

import firebase from 'firebase/app';
import 'firebase/firestore';

export type BaseModelHash = { [key: string]: BaseModel };
export interface IBaseModel {
  // common area
  id?: string;
  createDate?: Date;
  updateDate?: Date;
  updateUser?: string;
  version?: number;
}
/**
 * 全モデルの基底クラス
 */
export default abstract class BaseModel implements IBaseModel {
  id?: string;
  createDate?: Date = new Date();
  updateDate?: Date = new Date();
  updateUser?: string = 'default';
  version?: number = 0;

  /**
   * firestore更新処理用のフィールドの展開
   *
   */
  public toFirestoreCreateDocument(): any {
    const obj = this.expand();
    obj.createDate = firebase.firestore.FieldValue.serverTimestamp();
    obj.updateDate = firebase.firestore.FieldValue.serverTimestamp();
    delete obj.id;
    return obj;
  }

  public toFirestoreUpdateDocument(): any {
    const obj = this.expand();
    obj.updateDate = firebase.firestore.FieldValue.serverTimestamp();
    delete obj.id;
    return obj;
  }

  /**
   * フィールドの展開
   *
   */
  public expand(): any {
    return Object.assign({}, this);
  }

  public isEqual(other: BaseModel): boolean {
    return this.id === other.id && this.updateDate.getTime() === other.updateDate.getTime();
  }
}

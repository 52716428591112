import React, { lazy, Suspense } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import LoadingScreen from './views/components/common/LoadingScreen';
import AuthRoute from './views/app/AuthRoute';
import { PAGES } from './constants/pages';
import SlashScreen from './views/components/site/SplashScreen';
import AppLayout from './views/layouts/AppLayout';
import FirebaseMasterConnector from './views/app/FirebaseMasterConnector';
import GoogleAnalytics from './views/components/common/GoogleAnalytics';
import AppContext from './views/app/AppContext';

function Routes() {
  return (
    <Suspense fallback={<SlashScreen />}>
      <Switch>
        <Redirect exact from="/" to="/app" />
        <Route
          exact
          path={PAGES.error.notFound.path}
          component={lazy(() => import('./views/app/Error404View'))}
        />
        <Route
          exact
          path={PAGES.error.serviceUnavailable.path}
          component={lazy(() => import('./views/app/Error503View'))}
        />
        <Route
          exact
          path={PAGES.account.login.path}
          component={lazy(() => import('./views/accounts/LoginView'))}
        />
        <AuthRoute>
          <FirebaseMasterConnector>
            <AppContext>
              <GoogleAnalytics />
              <AppLayout>
                <Suspense fallback={<LoadingScreen />}>
                  <Switch>
                    <Redirect exact from="/app" to={PAGES.dashboard.path} />
                    <Route
                      exact
                      path={PAGES.dashboard.path}
                      component={lazy(() => import('./views/dashboard/DashboardView'))}
                    />
                    <Route
                      exact
                      path={PAGES.worker.scheduleAll.path}
                      component={lazy(() => import('./views/schedule/WorkerAllMonthView'))}
                    />
                    <Route
                      exact
                      path={PAGES.patient.scheduleAll.path}
                      component={lazy(() => import('./views/schedule/PatientAllMonthView'))}
                    />
                    <Route
                      exact
                      path={PAGES.patient.list.path}
                      component={lazy(() => import('./views/patients/PatientListView'))}
                    />
                    <Route
                      exact
                      path={PAGES.patient.create.path}
                      component={lazy(() => import('./views/patients/PatientCreateView'))}
                    />
                    <Route
                      exact
                      path={PAGES.patient.schedule.path}
                      component={lazy(() => import('./views/schedule/PatientEditView'))}
                    />
                    <Route
                      exact
                      path={PAGES.worker.schedule.path}
                      component={lazy(() => import('./views/schedule/WorkerEditView'))}
                    />
                    <Route
                      exact
                      path={PAGES.worker.assign.path}
                      component={lazy(() => import('./views/schedule/AssignView'))}
                    />
                    <Route
                      exact
                      path={PAGES.worker.salary.path}
                      component={lazy(() => import('./views/salary/WorkerListView'))}
                    />
                    <Route
                      exact
                      path={PAGES.worker.list.path}
                      component={lazy(() => import('./views/workers/WorkerListView'))}
                    />
                    <Route
                      exact
                      path={PAGES.worker.create.path}
                      component={lazy(() => import('./views/workers/WorkerCreateView'))}
                    />
                    <Route
                      exact
                      path={PAGES.setting.detail.path}
                      component={lazy(() => import('./views/settings/SettingDetailsView'))}
                    />
                    <Route
                      exact
                      path={PAGES.setting.dataload.path}
                      component={lazy(() => import('./views/settings/DataLoadView'))}
                    />
                    <Route
                      exact
                      path={PAGES.careservice.list.path}
                      component={lazy(() => import('./views/careservice/CareserviceListView'))}
                    />
                    <Route
                      exact
                      path={PAGES.account.list.path}
                      component={lazy(() => import('./views/accounts/AccountsListView'))}
                    />
                    <Route
                      exact
                      path={PAGES.account.edit.path}
                      component={lazy(() => import('./views/accounts/AccountsEditView'))}
                    />
                    <Route
                      exact
                      path={PAGES.account.edit.path}
                      component={lazy(() => import('./views/careservice/CareserviceListView'))}
                    />
                    <Route
                      exact
                      path={PAGES.chat.path}
                      component={lazy(() => import('./views/chat/ChatView'))}
                    />
                    <Route
                      exact
                      path={PAGES.navigation.path}
                      component={lazy(() => import('./views/navigation/NavigationView'))}
                    />
                    {/* detailは一番最後にする必要がある */}
                    <Route
                      exact
                      path={PAGES.worker.detail.path}
                      component={lazy(() => import('./views/workers/WorkerDetailsView'))}
                    />
                    <Route
                      exact
                      path={PAGES.patient.detail.path}
                      component={lazy(() => import('./views/patients/PatientDetailsView'))}
                    />
                    <Redirect to={PAGES.error.notFound.path} />
                  </Switch>
                </Suspense>
              </AppLayout>
            </AppContext>
          </FirebaseMasterConnector>
        </AuthRoute>
        <Redirect to={PAGES.error.notFound.path} />
      </Switch>
    </Suspense>
  );
}

export default Routes;

import axios from './axios';

export const googleGeolocationURL = 'https://maps.googleapis.com/maps/api/geocode/json?components=country:JP';
export const googleDirectionURL = 'https://www.google.com/maps/dir/?api=1&travelmode=driving&destination=';

export async function getLatLng(address: string) {
  // 住所検索APIから緯度経度を取得する
  const result = await axios.get(googleGeolocationURL, {
    params: {
      address,
      key: process.env.REACT_APP_GOOGLE_MAP_APIKEY,
    },
  });
  if (result.data.results.length) {
    const { lat, lng } = result.data.results[0].geometry.location;
    return { lat, lng };
  } else {
    return { lat: 0, lng: 0 };
  }
}

// origin,destination = 35.5115484,119.445045
// mode driving |
export function getDirectionUrl(destination: string, mode = 'driving') {
  return `https://www.google.com/maps/dir/?api=1&travelmode=${mode}&destination=${destination}`;
}
